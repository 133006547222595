import {
  CardComponent,
  CardCVV,
  CardExpiry,
  CardNumber,
} from '@chargebee/chargebee-js-react-wrapper';
import ChargebeeComponents from '@chargebee/chargebee-js-react-wrapper/dist/components/ComponentGroup';
import { Styles } from '@chargebee/chargebee-js-types';
import { Ref, useRef, useState } from 'react';
import styled from 'styled-components';
import { HelpTooltip } from '../ui/HelpTooltip';
import { initChargebee } from './chargebee';
import { CvvTooltipContent } from './CvvTooltipContent';

initChargebee();

const cardStyles: Styles = {
  base: {
    color: 'rgb(0, 0, 0)',
    fontFamily: 'Helvetica, sans-serif',
    fontSize: '16px',
  },
  invalid: {
    color: '#ec310b',
  },
  empty: {},
};

const StyledCardComponent = styled(CardComponent)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  & > *:first-child {
    grid-column: 1 / 3;
  }
`;

const baseInputCss = `
  height: 56px;
  border-radius: 4px;
  outline: 1px solid #c4cdd5;
  display: flex;
  align-items: center;
  padding: 0 10px;
  cursor: text;
  transition: outline-color 0.1s ease, outline-width 0.1s ease;

  &.CbHosted--focus {
    outline-color: #0070f3;
    outline-width: 2px;
  }

  &.CbHosted--invalid {
    outline-color: #e41029;
    outline-width: 2px;
  }
`;

const StyledCardNumber = styled(CardNumber)`
  ${baseInputCss}
`;

const StyledCardExpiry = styled(CardExpiry)`
  ${baseInputCss}
`;

const StyledCardCVV = styled(CardCVV)`
  ${baseInputCss}
`;

const StyledFormGroupError = styled.div`
  margin-top: 5px;
  color: #ec310b;
`;

const StyledFormGroupLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  padding-bottom: 0.35em;
`;

function FormGroup({
  label,
  children,
  error,
  tooltipContent,
}: {
  label: string;
  children: React.ReactNode;
  error?: string;
  tooltipContent?: React.ReactElement;
}) {
  return (
    <div>
      <StyledFormGroupLabel>
        {label}
        {tooltipContent && <HelpTooltip content={tooltipContent} />}
      </StyledFormGroupLabel>
      {children}
      {error && <StyledFormGroupError>{error}</StyledFormGroupError>}
    </div>
  );
}

type ChargebeeCardField = 'number' | 'expiry' | 'cvv';
type ChargebeeFieldErrorCode =
  | 'card_number_invalid'
  | 'card_number_incomplete'
  | 'invalid_card'
  | 'card_expiry_past'
  | 'card_expiry_invalid'
  | 'card_expiry_incomplete'
  | 'card_cvv_incomplete'
  | 'card_cvv_invalid';

interface ChargebeeFieldEvent {
  field: ChargebeeCardField;
  complete: boolean;
  error?: {
    errorCode: ChargebeeFieldErrorCode;
    message: string;
  };
}

export function ChargebeeCardComponent({
  cardRef,
  onValidation,
}: {
  cardRef: Ref<ChargebeeComponents>;
  onValidation: (isValid: boolean) => void;
}) {
  const [errors, setErrors] = useState<
    Record<ChargebeeCardField, string | undefined>
  >({
    cvv: undefined,
    expiry: undefined,
    number: undefined,
  });
  const fieldToValidity = useRef<Record<ChargebeeCardField, boolean>>({
    cvv: false,
    expiry: false,
    number: false,
  });

  function captureFieldValidity(event: ChargebeeFieldEvent) {
    fieldToValidity.current[event.field] = !event.error && event.complete;
    onValidation(
      Object.values(fieldToValidity.current).every((valid) => valid),
    );
  }

  function handleChange(event: ChargebeeFieldEvent) {
    captureFieldValidity(event);
    setErrors({
      ...errors,
      [event.field]: event.error?.message,
    });
  }

  return (
    <StyledCardComponent
      placeholder={{
        number: ' ',
        expiry: 'MM / YY',
        cvv: 'CVV2, CVC2, etc.',
      }}
      styles={cardStyles}
      ref={cardRef}
      // chargebee card component has wrong onChange typing
      onChange={handleChange as unknown as React.ChangeEventHandler}
    >
      <FormGroup label="Card Number" error={errors.number}>
        <StyledCardNumber />
      </FormGroup>
      <FormGroup label="Expiration" error={errors.expiry}>
        <StyledCardExpiry />
      </FormGroup>
      <FormGroup
        label="Security Code"
        error={errors.cvv}
        tooltipContent={<CvvTooltipContent />}
      >
        <StyledCardCVV />
      </FormGroup>
    </StyledCardComponent>
  );
}
