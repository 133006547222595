export const CanadianProvinces = [
  'Ontario',
  'Quebec',
  'Nova Scotia',
  'New Brunswick',
  'Manitoba',
  'British Columbia',
  'Prince Edward Island',
  'Saskatchewan',
  'Alberta',
  'Newfoundland and Labrador',
];

export const CanadianProvincesOptions = CanadianProvinces.map((province) => ({
  text: province,
  value: province,
}));
