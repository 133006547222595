import * as React from 'react';
import { animated, config, useSpring } from 'react-spring';
import styled from 'styled-components';
import CheckImage from '../shared/assets/check-image.svg?react';

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  align-items: center;
  justify-content: center;
  z-index: 2;
`;
const ModalBox = styled.div`
  border-radius: 4px;
  width: 380px;
  text-align: center;
  padding: 80px 50px;
  background-color: white;
  position: relative;
  @media (max-width: 768px) {
    width: 95%;
    margin: auto;
    box-sizing: border-box;
    padding: 25px;
  }
`;
const CheckIconWrapper = styled.div``;

interface ModalProps {
  body: React.ReactNode;
}

export const Modal = ({ body }: ModalProps) => {
  const zoomIn = useSpring({
    from: { transform: 'scale(0.5)' },
    to: { transform: 'scale(1)' },
    config: config.stiff,
  });

  return (
    <Wrapper>
      <animated.div style={zoomIn}>
        <ModalBox>
          <CheckIconWrapper>
            <CheckImage />
          </CheckIconWrapper>
          {body}
        </ModalBox>
      </animated.div>
    </Wrapper>
  );
};
