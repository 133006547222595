import styled from 'styled-components';
import ChatIcon from '../shared/assets/ic-chat.svg?react';
import PhoneIcon from '../shared/assets/ic-phone.svg?react';
import { openSupportChat } from '../shared/helpers/intercomHelpers';

const Wrapper = styled.div`
  margin-bottom: 30px;
`;
const Title = styled.h2`
  font-size: 48px;
  margin: 32px 0;
`;
const Desc = styled.p`
  font-size: 18px;
  letter-spacing: 0.38px;
  max-width: 448px;
  color: #505050;
  line-height: 28px;
`;
const Footer = styled.div`
  color: #505050;
  margin-top: 32px;
  display: flex;
`;
const FooterItem = styled.a`
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  align-items: end;
  transition: 0.2s;
  &:first-child {
    margin-right: 32px;
  }
  & > *:first-child {
    margin-right: 8px;
  }
  &:hover {
    color: #0075ff;
  }
`;
const FooterItemText = styled.span`
  border-bottom: 1px solid #c4cdd5;
  padding-bottom: 2px;
  &:hover {
    border-color: #0075ff;
  }
`;

export const GetStartedNotes = () => {
  const handleChatClick = () => {
    openSupportChat();
  };

  return (
    <Wrapper>
      <Title>Get Started</Title>
      <Desc>
        To get started, we just need a little information from you. Create your
        account to begin your access to the Super Dispatch platform, including
        the Carrier TMS and Super Loadboard.
      </Desc>
      <Footer>
        <FooterItem onClick={handleChatClick}>
          <ChatIcon />
          <FooterItemText>Support Chat</FooterItemText>
        </FooterItem>
        <FooterItem href="tel:+1(816)974-7002">
          <PhoneIcon />
          <FooterItemText>+1 (816) 974-7002</FooterItemText>
        </FooterItem>
      </Footer>
    </Wrapper>
  );
};
