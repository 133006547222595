import styled from 'styled-components';

const TermsOfServie = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #6a707c;
`;
const TermsOfServieBlack = styled.a`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #192334;
  text-decoration: none;
  border-bottom: 1px solid #c4cdd5;
  white-space: nowrap;
  transition: 0.2s;
  &:hover {
    border-color: #0075ff;
    color: #0075ff;
  }
`;

export function TermsOfService() {
  return (
    <TermsOfServie>
      By creating your account you accept our{' '}
      <TermsOfServieBlack
        target="blank"
        href="https://superdispatch.com/terms-of-service/"
      >
        Terms of Service
      </TermsOfServieBlack>{' '}
      and{' '}
      <TermsOfServieBlack
        target="blank"
        href="https://www.superdispatch.com/privacy-policy"
      >
        Privacy Policy
      </TermsOfServieBlack>
    </TermsOfServie>
  );
}
