import styled from 'styled-components';
import AmericanExpressIcon from '../../shared/assets/american-express-card.svg?react';
import VisaCardIcon from '../../shared/assets/visa-card.svg?react';

const StyledRoot = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  max-width: 250px;
`;

const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  grid-column: 1 / 3;
`;

export function CvvTooltipContent() {
  return (
    <StyledRoot>
      <VisaCardIcon />
      <AmericanExpressIcon />
      <StyledTextContainer>
        <div>
          For Visa, MasterCard and Discover the 3 digits on the back of the
          card.
        </div>
        <div>For American Express, the 4 digits on the front of the card.</div>
      </StyledTextContainer>
    </StyledRoot>
  );
}
