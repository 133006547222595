import { Text, TextStyles } from '@sdkit/typography';
import React from 'react';
import styled from 'styled-components';
import TickDone from '../../shared/assets/tick.svg?react';
import {
  validatePasswordStrength,
  validateToNumber,
  validateToPasswordLength,
  validateToSpecailCharacters,
  validateToUpperAndLowerCase,
} from '../helpers/validationHelpers';

const PasswordText = styled(Text)<{ passwordStrength: number }>`
  color: ${({ passwordStrength }) =>
    passwordStrength === 3
      ? '#D9210D'
      : passwordStrength === 4 || passwordStrength === 5
      ? '#DB7500'
      : passwordStrength === 6
      ? '#192334'
      : passwordStrength === 7
      ? '#19803D'
      : '#8F949E'};
`;

const Stepper = styled.div`
  height: 5px;
  display: flex;
  width: 100%;
  margin-bottom: 8px;
  margin-top: 4px;
`;

const StepperItem = styled.div<{
  passwordType?: 'weak' | 'average' | 'good' | 'strong';
}>`
  height: 2px;
  background-color: ${({ passwordType }) =>
    passwordType === 'weak'
      ? '#D9210D'
      : passwordType === 'average'
      ? '#DB7500'
      : passwordType === 'good' || passwordType === 'strong'
      ? '#19803D'
      : '#E1E5EA'};
  flex: 1;
  border-radius: 100px;
  &:not(:last-child) {
    margin-right: 10px;
    flex: 1;
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
`;

const ListItem = styled.div`
  display: flex;
  align-items: center;
`;

const Dot = styled.div`
  height: 4px;
  width: 4px;
  background-color: #0075ff;
  border-radius: 100px;
`;

const TickBox = styled.div`
  width: 13.33px;
  height: 13.33px;
  border-radius: 15px;
  background-color: #cce5ff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconWrapper = styled.div`
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface CheckPasswordItemProps {
  isDone: boolean;
  text: string;
}

const CheckPasswordItemText = styled(Text)<{ isDone?: boolean }>`
  text-decoration: ${({ isDone }) => (isDone ? 'line-through' : '')};
  color: ${({ isDone }) => (isDone ? '#8F949E' : '#192334')};
  word-spacing: 0.1em;
  line-height: 20px;
`;

function CheckPasswordItem({ isDone, text }: CheckPasswordItemProps) {
  return (
    <ListItem>
      <IconWrapper>
        {isDone ? (
          <TickBox>
            <TickDone />
          </TickBox>
        ) : (
          <Dot />
        )}
      </IconWrapper>
      <CheckPasswordItemText isDone={isDone} textType={TextStyles.Body}>
        {text}
      </CheckPasswordItemText>
    </ListItem>
  );
}

// if strength is weak, only first stepperItem will be red. If it is average, only two stepperItems will be orange. If it is good, 3 stepperItems will be green. if it is strong, 4 stepperItems will be green. Strong means that, if its length over 11 length and consists of at lease 2 characters and validate all cases(Upper&lower case, at least one number)
function passwordType(passwordStrength: number, k: number) {
  switch (true) {
    case passwordStrength === 3 && k === 0:
      return 'weak';
    case (passwordStrength === 4 || passwordStrength === 5) &&
      (k === 0 || k === 1):
      return 'average';
    case passwordStrength === 6 && (k === 0 || k === 1 || k === 2):
      return 'good';
    case passwordStrength === 7:
      return 'strong';
    default:
      return undefined;
  }
}

interface PasswordStrengthProps {
  value?: string;
}

export function PasswordStrength({ value }: PasswordStrengthProps) {
  const passwordStrength = React.useMemo(
    () => validatePasswordStrength(value),
    [value],
  );

  return (
    <>
      <PasswordText
        textType={TextStyles.Body}
        passwordStrength={passwordStrength}
      >
        {passwordStrength === 2 || passwordStrength === 1
          ? 'Password Strength'
          : passwordStrength === 3
          ? 'Weak Password'
          : passwordStrength === 4 || passwordStrength === 5
          ? 'Average Password'
          : passwordStrength === 6
          ? 'Good Password'
          : 'Strong Password'}
      </PasswordText>
      <Stepper>
        <StepperItem passwordType={passwordType(passwordStrength, 0)} />
        <StepperItem passwordType={passwordType(passwordStrength, 1)} />
        <StepperItem passwordType={passwordType(passwordStrength, 2)} />
        <StepperItem passwordType={passwordType(passwordStrength, 3)} />
      </Stepper>
      <Text textType={TextStyles.Header4}>It must have:</Text>
      <List>
        <CheckPasswordItem
          isDone={validateToPasswordLength(value)}
          text="At least 8 characters"
        />
        <CheckPasswordItem
          isDone={validateToUpperAndLowerCase(value)}
          text="Upper & lowercase letters"
        />
        <CheckPasswordItem isDone={validateToNumber(value)} text="A number" />
        <CheckPasswordItem
          isDone={validateToSpecailCharacters(value)}
          text="A special character (%, $, #, etc.)"
        />
      </List>
    </>
  );
}
