import axios, { AxiosRequestConfig } from 'axios';
import * as yup from 'yup';
import { getUserTimezone } from '../core/MobileHelpers';
import { SignupShipperData, SignupShipperResult } from './DTOs';

const SHIPPER_HOST =
  import.meta.env.VITE_SHIPPER_API_URL ||
  'https://api.staging.broker.superdispatch.org';

function requestShipperAPI<T>(config: AxiosRequestConfig): Promise<T> {
  const shipperHttpClient = axios.create({
    baseURL: SHIPPER_HOST,
  });

  return shipperHttpClient
    .request<T>(config)
    .then(({ data }) => data)
    .catch((error) => {
      if (error.isAxiosError) {
        throw error.response.data;
      }
      throw error;
    });
}

export function signupShipper(shipperData: SignupShipperData) {
  const params = new URLSearchParams(window.location.search);
  return requestShipperAPI<SignupShipperResult>({
    method: 'POST',
    url: '/sign-up',
    data: {
      company_name: shipperData.companyName.trim(),
      company_type: shipperData.companyType,
      email: shipperData.email.trim(),
      first_name: shipperData.firstName.trim(),
      last_name: shipperData.lastName.trim(),
      moves_cars_per_month: shipperData.carsPerMonth,
      source: 'STMS_Website',
      email_subscription: false,
      sms_subscription: false,
      country: shipperData.country,
      state: shipperData.state,
      password: shipperData.password,
      time_zone: getUserTimezone(),
      phone_number: `+${shipperData.phoneNumber.dialCode}${shipperData.phoneNumber.phone}`,
      is_test_account: params.get('is_test_account') === 'true',
    },
  });
}

export function signupShipperWithPaymentInfo(
  shipperData: SignupShipperData,
  chargebeeToken: string,
) {
  const params = new URLSearchParams(window.location.search);

  return requestShipperAPI<SignupShipperResult>({
    method: 'POST',
    url: '/sign-up/with-payment-info',
    data: {
      company_name: shipperData.companyName.trim(),
      company_type: shipperData.companyType,
      email: shipperData.email.trim(),
      first_name: shipperData.firstName.trim(),
      last_name: shipperData.lastName.trim(),
      moves_cars_per_month: shipperData.carsPerMonth,
      source: 'STMS_Website',
      email_subscription: false,
      sms_subscription: false,
      country: shipperData.country,
      state: shipperData.state,
      password: shipperData.password,
      time_zone: getUserTimezone(),
      phone_number: `+${shipperData.phoneNumber.dialCode}${shipperData.phoneNumber.phone}`,
      is_test_account: params.get('is_test_account') === 'true',
      payment_info: {
        token_id: chargebeeToken,
        country: shipperData.paymentInfo.country,
        state: shipperData.paymentInfo.state,
        zip: shipperData.paymentInfo.zip,
        address: shipperData.paymentInfo.address,
        address_second_line: shipperData.paymentInfo.addressSecondLine,
        city: shipperData.paymentInfo.city,
      },
    },
  });
}

export function isShipperSignupAvailable() {
  return requestShipperAPI<{ is_enabled: boolean }>({
    method: 'GET',
    url: '/sign-up',
  });
}

const featureToggleSchema = yup.object({
  data: yup.object({
    object: yup.object({
      is_enabled: yup.boolean().required(),
    }),
  }),
});

type FeatureToggleResponse = yup.InferType<typeof featureToggleSchema>;

export type ShipperPublicFeatureToggle =
  | 'website-signup.shipper.cc-details-experiment.ui'
  | 'signup.redirect-shipper.ui';

export function getShipperPublicFeatureToggle(
  featureToggle: ShipperPublicFeatureToggle,
) {
  return requestShipperAPI<FeatureToggleResponse>({
    method: 'GET',
    url: `/features/${featureToggle}`,
  });
}
