export function getSiteKey() {
  const testingMode = localStorage.getItem('CAPTCHA_TEST_MODE');
  switch (testingMode) {
    // https://developers.cloudflare.com/turnstile/reference/testing/
    case 'pass':
      return '1x00000000000000000000AA';
    case 'block':
      return '2x00000000000000000000AB';
    case 'force_interactive':
      return '3x00000000000000000000FF';
    default:
      return '0x4AAAAAAAD-dheBcA6EjcK3';
  }
}
