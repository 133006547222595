import * as React from 'react';
import { ErrorInfo } from 'react';
import { openSupportChat } from '../helpers/intercomHelpers';
import { captureError } from './datadog';

export interface ErrorBoundaryProps {
  children: React.ReactNode;
}

export interface ErrorBoundaryState {
  hasError?: boolean;
}

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    captureError(error, 'ErrorBoundary', errorInfo);
  }

  render() {
    if (this.state.hasError) {
      openSupportChat();
    }

    return this.props.children;
  }
}
