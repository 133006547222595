import { TextField as SDKitTextField, TextFieldProps } from '@sdkit/textfield';
import * as React from 'react';
import styled from 'styled-components';
import PasswordVisibilityIcon from '../../shared/assets/baseline-visibility.svg?react';
import PasswordVisibilityOffIcon from '../../shared/assets/baseline-visibility_off.svg?react';

const PasswordWrapper = styled.div<{ style?: React.CSSProperties }>`
  position: relative;
`;
const StyledTextField = styled(SDKitTextField)`
  height: 56px;
  border: 1px solid #c4cdd5;
  border-radius: 4px;
`;

const StyledTextFieldLabel = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
`;
const PasswordIcon = styled.span`
  position: absolute;
  right: 17px;
  top: 45px;
  cursor: pointer;
  & > span {
    color: #6a707c;
  }
`;

export function PasswordField({ label, ...props }: TextFieldProps) {
  const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);
  return (
    <PasswordWrapper>
      <StyledTextField
        {...props}
        type={isPasswordVisible ? 'text' : 'password'}
        label={<StyledTextFieldLabel>{label}</StyledTextFieldLabel>}
      />
      <PasswordIcon
        onClick={() => {
          setIsPasswordVisible(!isPasswordVisible);
        }}
      >
        {isPasswordVisible ? (
          <PasswordVisibilityIcon />
        ) : (
          <PasswordVisibilityOffIcon />
        )}
      </PasswordIcon>
    </PasswordWrapper>
  );
}
