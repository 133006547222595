import {
  arrow,
  FloatingArrow,
  offset,
  Placement,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
} from '@floating-ui/react';
import { cloneElement, ReactElement, ReactNode, useRef, useState } from 'react';
import styled from 'styled-components';

const StyledFloating = styled.div`
  color: #fff;
  padding: 8px 12px;
  font-size: 16px;
  max-width: 300px;
  word-wrap: break-word;
  font-weight: 400;
  line-height: 20px;
  border-radius: 4px;
  background-color: #192334;
`;

const ARROW_HEIGHT = 7;
const GAP = 4;

export function Tooltip({
  children,
  content,
  placement = 'right',
}: {
  children: ReactElement;
  content: ReactNode;
  placement?: Placement;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const arrowRef = useRef<SVGSVGElement>(null);
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement,
    middleware: [
      arrow({
        element: arrowRef,
      }),
      offset(ARROW_HEIGHT + GAP),
    ],
  });
  const hover = useHover(context);
  const focus = useFocus(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
  ]);

  return (
    <>
      {cloneElement(children, {
        ref: refs.setReference,
        ...getReferenceProps(),
      })}
      {isOpen && (
        <StyledFloating
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
        >
          <FloatingArrow ref={arrowRef} context={context} fill="#192334" />

          {content}
        </StyledFloating>
      )}
    </>
  );
}
