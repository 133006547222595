import { Select as SDKitSelect, SelectProps } from '@sdkit/select';
import * as React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Actions = styled.div<{ height?: number; error?: boolean }>`
  min-width: 120px;
  & > div > div > div:first-child {
    border: 1px solid #c4cdd5;
    ${({ error }) =>
      error &&
      css`
        border: 2px solid #ec310b;
      `};
    box-sizing: border-box;
    border-radius: 4px;
    ${({ height }) =>
      height &&
      css`
        height: ${height}px;
      `};
  }
  & > div > div > div > span > span {
    &::after {
      right: 0;
      background-repeat: no-repeat;
      transform: translate(12px, 5px);
      content: ' ';
      height: 18px;
      position: absolute;
      width: 36px;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEuNDEgMEw2IDQuNThMMTAuNTkgMEwxMiAxLjQxTDYgNy40MUwwIDEuNDFMMS40MSAwWiIgZmlsbD0iIzZBNzA3QyIvPjwvc3ZnPg==);
    }
  }
  & > div > div > div > span > span > span {
    color: #192334;
  }
  & > div > div > div > span:last-child {
    width: 0;
    overflow: hidden;
  }
`;
const Label = styled.div`
  margin-bottom: 0.35em;
  font-weight: 600;
  line-height: 24px;
`;
const ErrorLabel = styled.div`
  display: inline-block;
  margin-top: 5px;
  color: #ec310b;
`;

interface ISelectProps extends SelectProps {
  height?: number;
  errorMessage?: React.ReactNode;
}

export function SelectField({
  label,
  height,
  errorMessage,
  ...props
}: ISelectProps) {
  return (
    <Wrapper>
      <Label>{label}</Label>
      <Actions height={height} error={!!errorMessage}>
        <SDKitSelect {...props} />
      </Actions>
      {errorMessage && <ErrorLabel>{errorMessage}</ErrorLabel>}
    </Wrapper>
  );
}
