import { Checkbox } from '@sdkit/checkbox';
import { FORM_ERROR } from 'final-form';
import createDecorator from 'final-form-focus';
import * as React from 'react';
import { useState } from 'react';
import { Field, FieldMetaState, Form } from 'react-final-form';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';
import { Modal } from '../core/Modal';
import { TermsOfService } from '../core/TermsOfService';
import { SignupShipperData } from '../data/DTOs';
import { isShipperSignupAvailable, signupShipper } from '../data/ShipperAPI';
import ArrowForwardIcon from '../shared/assets/arrow-forward.svg?react';
import { AutoCompleteField } from '../shared/form/AutocompleteField';
import { countryOptions } from '../shared/form/CountryOptions';
import { StateAutocompleteField } from '../shared/form/StateAutocompleteField';
import {
  identifyAnalytics,
  trackEvent,
  trackPage,
} from '../shared/helpers/AnalyticsHelpers';
import { CaptchaWidget } from '../shared/helpers/CaptchaWidget';
import { openSupportChat } from '../shared/helpers/intercomHelpers';
import {
  composeValidators,
  disallowHtml,
  isSelected,
  isValidEmail,
  isValidPhone,
  required,
  validatePassword,
} from '../shared/helpers/validationHelpers';
import { Header } from '../shared/layout/Header';
import { Button } from '../shared/ui/Button';
import { FormGroup } from '../shared/ui/FormGroup';
import { PasswordField } from '../shared/ui/PasswordField';
import { PasswordStrength } from '../shared/ui/PasswordStrength';
import { PhoneField } from '../shared/ui/PhoneField';
import { RadioGroupField } from '../shared/ui/RadioGroupField';
import { SelectField } from '../shared/ui/SelectField';
import { TextField } from '../shared/ui/TextField';
import { captureError, logInfo, logWarning } from '../shared/utils/datadog';
import { sendToWebhook } from '../shared/webhooks/webhook';

const focusOnErrors = createDecorator();

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledContent = styled.div`
  max-width: 660px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 64px 24px;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ThankYouText = styled.div`
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 0.35px;
  font-weight: 600;
  margin-bottom: 16px;
  margin-top: 32px;
  max-width: 300px;
`;

const Message = styled.div`
  color: #6a707c;
  text-align: center;
  font-size: 18px;
`;

const OptionalTextFieldLabel = styled.span`
  & > span {
    color: #6a707c;
    font-weight: normal;
  }
`;
const StyledArrowForwardIcon = styled(ArrowForwardIcon)`
  margin-left: 10px;
`;
const PageTitle = styled.h1`
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  margin: 0;
`;
const PageSubtitle = styled.h2`
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  margin: 16px 0 0;
`;
const StyledForm = styled.form`
  display: inline-block;
`;

const ErrorMessage = styled.div`
  margin: 20px 0;
  padding: 16px;
  background: #fff7dc;
`;

const SubmitButtonAndTermsContainer = styled.div`
  max-width: 448px;
`;

const carsPerMonthOptions = [
  { label: '1-50', value: 1 },
  { label: '51-100', value: 51 },
  { label: '101-500', value: 101 },
  { label: '501-1,000', value: 501 },
  { label: '1,000+', value: 1000 },
  { label: '10,000+', value: 10000 },
];
const referralOptions = [
  'Google Search',
  'Email',
  'Social Media',
  'Events / Vendor Booth / Conference',
  'BOL',
  'Word of Mouth',
  'Other',
];

const validations = {
  firstName: composeValidators([
    required('Please enter a first name'),
    disallowHtml(),
  ]),
  lastName: composeValidators([
    required('Please enter a last name'),
    disallowHtml(),
  ]),
  companyName: composeValidators([
    required('Please enter a company name'),
    disallowHtml(),
  ]),
  country: required('Please enter Country'),
  companyType: required('Please enter Company type'),
  state: required('Please enter State'),
  email: isValidEmail('Please enter a valid email'),
  phone: isValidPhone('Please enter a valid phone number'),
  carsPerMonth: isSelected(carsPerMonthOptions.map((x) => x.value)),
};
const errors = (meta: FieldMetaState<unknown>) =>
  meta.error && meta.touched && <span>{meta.error}</span>;

const companyTypes = [
  'Online Marketplace',
  'Broker',
  'Dealer',
  'OEM',
  'Auction',
  'Rental',
  'Import/Export',
  'Financial Institution',
  'Wholesaler',
  'Uplifter',
];

const ConditionalField = ({ when, is, children }: any) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
);

const sendFivetranWebhookEvent = (e: SignupShipperData) => {
  const signupData = {
    company_name: e.companyName,
    company_type: e.companyType,
    email: e.email,
    first_name: e.firstName,
    heard_about_us:
      e.comment !== ''
        ? e.comment === 'Other'
          ? e.referralSource
          : e.comment
        : undefined,
    last_name: e.lastName,
    moves_cars_per_month: e.carsPerMonth,
    phone_number: `+${e.phoneNumber.dialCode}${e.phoneNumber.phone}`,
    source: 'STMS_Website',
    email_subscription: e.subscriptions,
    sms_subscription: e.subscriptions,
    country: e.country,
    state: e.state,
  };

  return sendToWebhook(signupData);
};

const identifyUserOnSegment = (
  userId: string,
  companyId: string | undefined,
  e: SignupShipperData,
  enableSalesForce = false,
) => {
  const contact = {
    email: e.email,
    firstName: e.firstName,
    lastName: e.lastName,
    phone: `+${e.phoneNumber.dialCode}${e.phoneNumber.phone}`,
    heard_about_us:
      e.comment !== ''
        ? e.comment === 'Other'
          ? e.referralSource
          : e.comment
        : undefined,
    company_name: e.companyName,
    company_type: e.companyType,
    cars_per_month: e.carsPerMonth,
    email_subscription: e.subscriptions,
    sms_subscription: e.subscriptions,
    source: 'STMS_Website',
  };

  identifyAnalytics(
    userId,
    {
      ...contact,
      companyType: e.companyType,
      creationSource: 'Website',
      company: { id: companyId, name: e.companyName },
    },
    {
      integrations: {
        Salesforce: enableSalesForce,
      },
    },
  );
};

export function SignupShipper() {
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const [isShowSuccessModal, setShowSuccessModal] = React.useState(false);
  const [isSubmitting, setSubmitting] = React.useState(false);
  const isSelfServe = useIsSelfServe();

  React.useEffect(() => {
    document.title = 'Shipper Sign Up';
    trackPage('Shipper Sign Up');
  }, []);

  const handleOnSubmit = async (shipperData: SignupShipperData) => {
    if (!captchaToken) {
      logWarning('Shipper missing Captcha token', shipperData);
      // eslint-disable-next-line no-alert
      alert('Failed to verify Captcha. Please try again.');
      return;
    }

    try {
      if (!isSubmitting) {
        setSubmitting(true);
        trackEvent('Sign up submitted', {
          companyType: shipperData.companyType,
        });
        logInfo('Shipper Sign up submitted', {
          ...shipperData,
          password: null,
        });

        if (import.meta.env.VITE_TARGET === 'production') {
          await sendFivetranWebhookEvent(shipperData);
        }

        if (isSelfServe && shipperData.carsPerMonth <= 50) {
          const { broker_url, token, user_guid, shipper_guid } =
            await signupShipper(shipperData);

          identifyUserOnSegment(user_guid, shipper_guid, shipperData);
          trackEvent('Signed up', {
            isSelfServe: true,
            companyType: shipperData.companyType,
          });

          const shipperUrl = new URL(broker_url);
          shipperUrl.searchParams.append('token', token);
          shipperUrl.searchParams.append('utm_source', 'shipper_signup');
          shipperUrl.pathname = '/getting-started';
          window.location.assign(shipperUrl.toString());
        } else {
          identifyUserOnSegment(
            shipperData.email,
            undefined,
            shipperData,
            true,
          );

          trackEvent('Signed up', {
            companyType: shipperData.companyType,
          });
          setSubmitting(false);
          setShowSuccessModal(true);
        }
      }
    } catch (error: any) {
      setSubmitting(false);
      captureError(error, 'ShipperSignup#submit', shipperData);

      const errorDetails =
        error?.data?.details && Object.values(error.data.details);
      const errorMessage = error?.data?.message;

      if (errorDetails) {
        return {
          [FORM_ERROR]: (
            <div>
              {errorDetails.map((detail: any, key: number) => (
                <div key={key}>{detail}</div>
              ))}
            </div>
          ),
        };
      }

      if (errorMessage) {
        return { [FORM_ERROR]: errorMessage };
      }

      // eslint-disable-next-line no-alert
      alert('Something went wrong! Please contact support!');
      openSupportChat();
    }
  };

  return (
    <>
      <StyledRoot>
        <Header type="shipper" />

        <StyledContent>
          <ContentEnterAnimation>
            <PageTitle>Get Started using Super Dispatch</PageTitle>
            <PageSubtitle>Begin your free trial today</PageSubtitle>

            <Form
              initialValues={{
                firstName: '',
                lastName: '',
                companyName: '',
                email: '',
                phoneNumber: { iso2: 'us', dialCode: '1', phone: '' },
                companyType: '',
                carsPerMonth: 1,
                comment: '',
                subscriptions: false,
                referralSource: '',
                country: 'US',
                state: '',
                password: '',
              }}
              keepDirtyOnReinitialize={true}
              onSubmit={handleOnSubmit}
              decorators={[focusOnErrors]}
              render={({ handleSubmit, values, submitFailed, submitError }) => (
                <StyledForm onSubmit={handleSubmit}>
                  <FormGroup>
                    <Field name="firstName" validate={validations.firstName}>
                      {({ input, meta }) => (
                        <TextField
                          {...input}
                          autoFocus={true}
                          label="First Name"
                          errorMessage={errors(meta)}
                          maxLength={255}
                        />
                      )}
                    </Field>
                    <Field name="lastName" validate={validations.lastName}>
                      {({ input, meta }) => (
                        <TextField
                          {...input}
                          label="Last Name"
                          errorMessage={errors(meta)}
                          maxLength={255}
                        />
                      )}
                    </Field>
                  </FormGroup>
                  <FormGroup>
                    <Field
                      name="companyName"
                      validate={validations.companyName}
                    >
                      {({ input, meta }) => (
                        <TextField
                          {...input}
                          label="Legal Company Name"
                          errorMessage={errors(meta)}
                          maxLength={255}
                        />
                      )}
                    </Field>
                  </FormGroup>

                  <FormGroup>
                    <AutoCompleteField
                      name="country"
                      label="Country"
                      placeholder="Select"
                      options={countryOptions}
                      validate={validations.country}
                    />

                    <StateAutocompleteField
                      name="state"
                      placeholder="Select"
                      label="State/Province"
                      country={values.country}
                      validate={validations.state}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Field name="email" validate={validations.email}>
                      {({ input, meta }) => (
                        <TextField
                          {...input}
                          label="Email"
                          errorMessage={errors(meta)}
                          maxLength={255}
                        />
                      )}
                    </Field>
                  </FormGroup>
                  <FormGroup>
                    <Field
                      name="phoneNumber"
                      validate={(value) =>
                        validations.phone(
                          `+${value.dialCode}${value.phone}`,
                          value.iso2,
                        )
                      }
                    >
                      {({ input, meta }) => (
                        <PhoneField
                          {...input}
                          placeholder="Your phone number"
                          label="Phone Number"
                          separateDialCode={true}
                          errorMessage={errors(meta)}
                        />
                      )}
                    </Field>
                  </FormGroup>
                  {isSelfServe && values.carsPerMonth <= 50 && (
                    <>
                      <FormGroup>
                        <Field
                          name="password"
                          maxLength={255}
                          validate={validatePassword}
                        >
                          {({ input, meta }) => (
                            <PasswordField
                              {...input}
                              label="Password"
                              placeholder="Enter 8 characters or more"
                              errorMessage={!!meta.error && submitFailed && ' '}
                            />
                          )}
                        </Field>
                      </FormGroup>

                      <PasswordStrength value={values.password} />
                    </>
                  )}
                  <FormGroup>
                    <Field
                      name="carsPerMonth"
                      validate={validations.carsPerMonth}
                    >
                      {({ input, meta }) => (
                        <RadioGroupField
                          column={true}
                          values={carsPerMonthOptions}
                          name="carsPerMonth"
                          checked={carsPerMonthOptions.findIndex(
                            (x) => x.value === input.value,
                          )}
                          label="How many cars do you move per month?"
                          errorMessage={errors(meta)}
                          onClick={(value: any) => {
                            input.onChange(value);
                          }}
                        />
                      )}
                    </Field>
                  </FormGroup>
                  <FormGroup>
                    <Field
                      name="companyType"
                      validate={validations.companyType}
                    >
                      {({ input: { value, onChange }, meta }) => (
                        <SelectField
                          value={value && companyTypes.indexOf(value)}
                          label="Company Type"
                          height={56}
                          placeholder="Select a company type..."
                          errorMessage={
                            meta.touched &&
                            (!value || !companyTypes.includes(value)) &&
                            'Please select a company type'
                          }
                          items={
                            companyTypes.map((o, i) => ({
                              value: i,
                              text: o,
                            })) as any
                          }
                          onChange={(v) => {
                            onChange(companyTypes[Number(v)]);
                          }}
                        />
                      )}
                    </Field>

                    <Field name="comment">
                      {({ input: { value, onChange } }) => (
                        <SelectField
                          value={referralOptions.indexOf(value)}
                          label={
                            <OptionalTextFieldLabel>
                              How did you hear about us? <span>(optional)</span>
                            </OptionalTextFieldLabel>
                          }
                          height={56}
                          placeholder="Select a source..."
                          items={
                            referralOptions.map((o, i) => ({
                              value: i,
                              text: o,
                            })) as any
                          }
                          onChange={(v) => {
                            onChange(referralOptions[Number(v)]);
                          }}
                        />
                      )}
                    </Field>
                  </FormGroup>

                  <ConditionalField when="comment" is="Other">
                    <FormGroup>
                      <Field name="referralSource">
                        {({ input, meta }) => (
                          <TextField
                            {...input}
                            maxLength={255}
                            errorMessage={errors(meta)}
                            placeholder="Please, specify the source..."
                          />
                        )}
                      </Field>
                    </FormGroup>
                  </ConditionalField>

                  <FormGroup>
                    <CaptchaWidget
                      onTokenChange={(token) => {
                        setCaptchaToken(token);
                      }}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Field name="subscriptions">
                      {({ input }) => (
                        <Checkbox
                          {...input}
                          checked={input.value}
                          label="I agree to receive SMS and Email messages from Super Dispatch such as the latest offers and deals."
                        />
                      )}
                    </Field>
                  </FormGroup>

                  {submitError === 'User name should be unique' ? (
                    <ErrorMessage>
                      The email you entered is already in use. If it&apos;s
                      yours,{' '}
                      <a href="https://shipper.superdispatch.com/signin">
                        log in
                      </a>{' '}
                      or{' '}
                      <a
                        href="#"
                        onClick={(event) => {
                          event.preventDefault();
                          openSupportChat();
                        }}
                      >
                        contact support
                      </a>{' '}
                      to reset password.
                    </ErrorMessage>
                  ) : submitError === 'Shipper name should be unique' ? (
                    <ErrorMessage>
                      The company name you entered is already in use. If
                      you&apos;re part of this company, please{' '}
                      <a href="https://shipper.superdispatch.com/signin">
                        log in
                      </a>{' '}
                      or{' '}
                      <a
                        href="#"
                        onClick={(event) => {
                          event.preventDefault();
                          openSupportChat();
                        }}
                      >
                        contact support
                      </a>{' '}
                      for help with your account.
                    </ErrorMessage>
                  ) : submitError != null ? (
                    <ErrorMessage>{submitError}</ErrorMessage>
                  ) : null}

                  <SubmitButtonAndTermsContainer>
                    {isSelfServe ? (
                      <Button type="submit" isLoading={isSubmitting}>
                        Get Started Now
                      </Button>
                    ) : (
                      <Button type="submit" isLoading={isSubmitting}>
                        Request Demo <StyledArrowForwardIcon />
                      </Button>
                    )}

                    <TermsOfService />
                  </SubmitButtonAndTermsContainer>
                </StyledForm>
              )}
            />
          </ContentEnterAnimation>
        </StyledContent>
      </StyledRoot>

      {isShowSuccessModal && <SuccessDialog />}
    </>
  );
}

function useIsSelfServe() {
  const [isSelfServe, setIsSelfServe] = React.useState(false);

  React.useEffect(() => {
    void isShipperSignupAvailable().then(({ is_enabled }) => {
      setIsSelfServe(is_enabled);
    });
  }, []);

  return isSelfServe;
}

function SuccessDialog() {
  return (
    <Modal
      body={
        <ModalBody>
          <ThankYouText>Thanks for trying out Super Dispatch!</ThankYouText>
          <Message>Check your email for next steps.</Message>
        </ModalBody>
      }
    />
  );
}

function ContentEnterAnimation({ children }: { children: React.ReactNode }) {
  const slide = useSpring({
    from: { transform: 'translateX(100%)' },
    to: { transform: 'translateX(0%)' },
  });

  return (
    <animated.div style={window.innerWidth > 1024 ? slide : {}}>
      {children}
    </animated.div>
  );
}
