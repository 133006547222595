import { useEffect, useState } from 'react';
import {
  getShipperPublicFeatureToggle,
  ShipperPublicFeatureToggle,
} from '../data/ShipperAPI';

export function useShipperPublicFeatureToggle(
  featureFlag: ShipperPublicFeatureToggle,
) {
  const [isEnabled, setIsEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // for quick on/off testing
    const localStorageKey = `shipper-public-feature-toggle-${featureFlag}`;
    const localStorageValue = localStorage.getItem(localStorageKey);

    if (
      import.meta.env.VITE_TARGET !== 'production' &&
      localStorageValue !== null
    ) {
      setIsEnabled(localStorageValue === 'true');
      setIsLoading(false);
      return;
    }

    void getShipperPublicFeatureToggle(featureFlag)
      .then(({ data }) => {
        setIsEnabled(data.object.is_enabled);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [featureFlag]);

  return [isEnabled, isLoading] as const;
}
