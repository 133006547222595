import { ChargebeeInstance, InitOptions } from '@chargebee/chargebee-js-types';

const PUBLISHABLE_KEY = 'test_mIWAU5ur3mqztlTwplJcuOKbBpcuWAhXDn';

declare global {
  interface Window {
    Chargebee: {
      init: (options: InitOptions) => void;
      getInstance: () => ChargebeeInstance;
    };
  }
}

export function initChargebee() {
  const site =
    import.meta.env.VITE_TARGET !== 'production'
      ? 'superdispatch-test'
      : 'superdispatch';

  window.Chargebee.init({
    site,
    publishableKey: PUBLISHABLE_KEY,
  });
}
