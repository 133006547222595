import * as React from 'react';
import styled from 'styled-components';
import DotPattern from '../shared/assets/dot-pattern-testimonial.svg?react';

const Wrapper = styled.div`
  width: 446px;
  height: 446px;
  background-color: white;
  border: 1px solid #e1e5ea;
  border-radius: 4px;
  position: relative;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
`;

const WrapperWithImage = styled.div`
  position: relative;
  top: -100px;
  left: 70px;
  width: 446px;
  height: 446px;
  background-color: white;
  border: 1px solid #e1e5ea;
  border-radius: 4px;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
`;

const DotPatternBackground = styled(DotPattern as any)`
  width: 100%;
  height: 100%;
  position: absolute;
  right: -62px;
  bottom: -62px;
  z-index: -1;
  background-position: inherit;
`;

interface TestimonialCardProps {
  body: React.ReactNode;
}

export const TestimonialCard = ({ body }: TestimonialCardProps) => (
  <Wrapper>
    {body}
    <DotPatternBackground />
  </Wrapper>
);

interface TestimonialCardWithImageProps extends TestimonialCardProps {
  src: string;
}

export const TestimonialCardWithImage = ({
  body,
  src,
}: TestimonialCardWithImageProps) => (
  <div style={{ position: 'relative' }}>
    <div style={{ position: 'relative', zIndex: 2 }}>
      <img style={{ borderRadius: '40px 10px' }} src={src} />
    </div>
    <WrapperWithImage>
      {body}
      <DotPatternBackground />
    </WrapperWithImage>
  </div>
);
