import * as yup from 'yup';

const backendValidationSchema = yup.object({
  status: yup.string().required(),
  data: yup.object({
    message: yup.string().required(),
    error_id: yup
      .string()
      .oneOf([
        'VALIDATION_ERROR',
        'SHIPPER_NAME_ALREADY_EXISTS',
        'USERNAME_ALREADY_EXISTS',
        'CHARGEBEE_PAYMENT_METHOD_ERROR',
      ])
      .required(),
    details: yup
      .object({
        companyName: yup.string(),
        email: yup.string(),
        firstName: yup.string(),
        lastName: yup.string(),
        movesCarsPerMonth: yup.string(),
        phoneNumber: yup.string(),
      })
      .nullable(),
  }),
});

export function parseBackendValidation(data: unknown) {
  return backendValidationSchema.validate(data);
}

export type BackendValidation = yup.InferType<typeof backendValidationSchema>;
export type BackendValidationFieldKey = keyof NonNullable<
  BackendValidation['data']['details']
>;

const ACCOUNT_INFORMATION_FIELD_KEYS: BackendValidationFieldKey[] = [
  'firstName',
  'lastName',
  'companyName',
  'phoneNumber',
  'email',
];

export function checkHasAccountInformationBackendValidationErrors({
  data,
}: BackendValidation): boolean {
  if (!data.details) {
    return (
      data.error_id === 'SHIPPER_NAME_ALREADY_EXISTS' ||
      data.error_id === 'USERNAME_ALREADY_EXISTS'
    );
  }

  return ACCOUNT_INFORMATION_FIELD_KEYS.some((key) =>
    Boolean(data.details?.[key]),
  );
}
