import { useEffect, useState } from 'react';
import { fetchFeatures } from '../data/CarrierAPI';

export function useCarrierFeatureToggles() {
  const [features, setFeatures] = useState<Record<string, boolean | null>>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    void fetchFeatures().then(({ data }) => {
      setFeatures(data);
      setIsLoading(false);
    });
  }, []);

  return { features, isLoading };
}
