import * as React from 'react';
import { Field, FieldMetaState } from 'react-final-form';
import styled from 'styled-components';
import { SignupCarrierType } from '../../data/DTOs';
import ArrowForwardIcon from '../../shared/assets/arrow-forward.svg?react';
import { isSelected, required } from '../../shared/helpers/validationHelpers';
import { Button } from '../../shared/ui/Button';
import { FormGroup } from '../../shared/ui/FormGroup';
import { RadioGroupField } from '../../shared/ui/RadioGroupField';
import { SelectField } from '../../shared/ui/SelectField';
import { TextField } from '../../shared/ui/TextField';
import { sendToWebhook } from '../../shared/webhooks/webhook';

const Wrapper = styled.div`
  margin-top: 20px;
`;
const StyledArrowForwardIcon = styled(ArrowForwardIcon)`
  margin-left: 10px;
`;
const OptionalTextFieldLabel = styled.span`
  & > span {
    color: #6a707c;
    font-weight: normal;
  }
`;
const RoleWrapper = styled(FormGroup)`
  flex-direction: column;
`;

const referralOptions = [
  'Shipper Request',
  'Broker Request',
  'Google Search',
  'Email',
  'Social Media',
  'Events / Vendor Booth / Conference',
  'Other',
];
const roles = [
  'Dispatcher',
  'Fleet Manager',
  'Owner',
  'Billing',
  'Driver',
  'Other',
];
const driversCountOptions = [
  { label: '1', value: 1 },
  { label: '2-5', value: 2 },
  { label: '6-10', value: 6 },
  { label: '10+', value: 10 },
];
const perBrokerRequest = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

const validations = {
  role: isSelected(roles, 'Please choose one of the options'),
  number_of_trucks: isSelected(
    driversCountOptions.map((c) => c.value),
    'Please choose one of the options',
  ),
  comment: isSelected(referralOptions, 'Please choose one of the options'),
  referralSource: required('Please enter a source'),
  roleOther: required('Please enter your role'),
};

const errors = (meta: FieldMetaState<unknown>) => {
  if (meta.error && meta.touched) {
    return <span>{meta.error}</span>;
  }
  if (meta.submitError && !meta.touched) {
    return <span>{meta.submitError}</span>;
  }
};

const ConditionalField = ({ when, is, children }: any) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
);

interface AdditionalInfoProps {
  submitting?: boolean;
  wizardData: SignupCarrierType & { carrierData?: any; carrierStatus: boolean };
}

export function AdditionalInfo(props: AdditionalInfoProps) {
  React.useEffect(() => {
    document.title = 'Carrier Sign Up | Additional Information';
  }, []);

  React.useEffect(() => {
    if (import.meta.env.VITE_TARGET === 'production') {
      void sendToWebhook({
        first_name: props.wizardData.first_name,
        last_name: props.wizardData.last_name,
        email: props.wizardData.email,
        phone_number: `+${props.wizardData.phoneNumber.dialCode}${props.wizardData.phoneNumber.phone}`,
        email_subscription: props.wizardData.subscriptions,
        sms_subscription: props.wizardData.subscriptions,
        source: 'CTMS_Website',
      });
    }
  }, [props.wizardData]);

  return (
    <Wrapper>
      <RoleWrapper>
        <Field name="role" validate={(v) => validations.role(v)}>
          {({ input, meta }) => (
            <RadioGroupField
              column={true}
              checked={roles.findIndex((r) => r === input.value)}
              values={roles.map((role, index) => ({
                label: role,
                value: index,
              }))}
              name="role"
              label="Select your role"
              errorMessage={errors(meta)}
              onClick={(value: any) => {
                input.onChange(roles[value]);
              }}
            />
          )}
        </Field>
        <ConditionalField when="role" is="Other">
          <Field name="role_other" validate={validations.roleOther}>
            {({ input, meta }) => (
              <TextField
                {...input}
                maxLength={255}
                placeholder="Enter your role"
                errorMessage={errors(meta)}
              />
            )}
          </Field>
        </ConditionalField>
      </RoleWrapper>
      <FormGroup>
        <Field name="number_of_trucks" validate={validations.number_of_trucks}>
          {({ input, meta }) => (
            <RadioGroupField
              columnOnMobile={true}
              checked={driversCountOptions.findIndex(
                (r) => r.value === input.value,
              )}
              values={driversCountOptions}
              name="number_of_trucks"
              label="How many drivers does your company manage?"
              errorMessage={errors(meta)}
              onClick={(value: any) => {
                input.onChange(value);
              }}
            />
          )}
        </Field>
      </FormGroup>

      <FormGroup>
        <Field name="comment" validate={(v) => validations.comment(v)}>
          {({ input: { value, onChange }, meta }) => (
            <SelectField
              value={referralOptions.indexOf(value)}
              label={
                <OptionalTextFieldLabel>
                  How did you hear about us?
                </OptionalTextFieldLabel>
              }
              height={56}
              placeholder="Select a source..."
              errorMessage={errors(meta)}
              items={
                referralOptions.map((o, i) => ({ value: i, text: o })) as any
              }
              onChange={(v) => {
                onChange(referralOptions[Number(v)]);
              }}
            />
          )}
        </Field>
      </FormGroup>
      <ConditionalField when="comment" is="Other">
        <FormGroup>
          <Field name="referralSource" validate={validations.referralSource}>
            {({ input, meta }) => (
              <TextField
                {...input}
                maxLength={255}
                errorMessage={errors(meta)}
                placeholder="Please, specify the source..."
              />
            )}
          </Field>
        </FormGroup>
      </ConditionalField>
      <Button type="submit" size="large" isLoading={props.submitting}>
        Continue
        <StyledArrowForwardIcon />
      </Button>
    </Wrapper>
  );
}
