declare global {
  interface Window {
    Intercom?: (command: string) => void;
  }
}

export function openSupportChat(): void {
  if (window.Intercom) {
    window.Intercom('show');
  }
}
