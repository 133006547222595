import emailSpellChecker from '@zootools/email-spell-checker';
import * as React from 'react';
import { Field, FieldMetaState, useFormState } from 'react-final-form';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import { TermsOfService } from '../../core/TermsOfService';
import {
  APIResponse,
  isAPIErrorResponse,
  verifyEmail,
} from '../../data/CarrierAPI';
import ArrowForwardIcon from '../../shared/assets/arrow-forward.svg?react';
import {
  isValidEmail,
  required,
  validatePassword,
} from '../../shared/helpers/validationHelpers';
import { Button } from '../../shared/ui/Button';
import { FormGroup } from '../../shared/ui/FormGroup';
import { PasswordField } from '../../shared/ui/PasswordField';
import { PasswordStrength } from '../../shared/ui/PasswordStrength';
import { TextField } from '../../shared/ui/TextField';
import { VerifyEmail } from './VerifyEmail';

const Wrapper = styled.div`
  margin-top: 20px;
`;
const StyledArrowForwardIcon = styled(ArrowForwardIcon)`
  margin-left: 10px;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const HelperText = styled.span`
  font-size: 16px;
  margin: 5px 0;
  line-height: 20px;
  color: #8f949e;
  white-space: nowrap;
`;

const Link = styled.a`
  color: #0063db;
  cursor: pointer;
  text-decoration: none;
`;

const validations = {
  password: required('Please enter a password'),
  email: isValidEmail('Please enter a valid email'),
};

const errors = (meta: FieldMetaState<unknown>) => {
  if (meta.error && meta.touched) {
    return <span>{meta.error}</span>;
  }
  if (meta.submitError && !meta.touched) {
    return <span>{meta.submitError}</span>;
  }
};

interface CreateAccountProps {
  onUpdateTitle: (title: string) => void;
}

export function CreateAccount({ onUpdateTitle }: CreateAccountProps) {
  const {
    submitErrors,
    values,
    submitFailed: isSubmitFailed,
    valid,
  } = useFormState();
  const [showVerifyEmail, setShowVerifyEmail] = React.useState(false);
  const [sendVerificationCodeError, setSendVerificationCodeError] =
    React.useState<string>();
  const [previousEmail, setPreviousEmail] = React.useState<string>();
  const [sniperLink, setSniperLink] = React.useState<string>(); // Sniper link is used to open the email inbox in a new tab with the verification code filtered

  const sendVerificationCodeMutation = useMutation({
    mutationFn: verifyEmail,
    onMutate: () => {
      setSendVerificationCodeError(undefined);
    },
    onSuccess: (response: APIResponse<{ sniper_link: string }>) => {
      if (isAPIErrorResponse(response)) {
        response.error.type === 'TooManyRequests'
          ? toast.error('Too many requests, please try again later')
          : setSendVerificationCodeError(response.error.user_message);
      } else {
        handleShowVerifyEmail();
        setSniperLink(response.data?.sniper_link);
      }
    },
    onError: (error: Error) => {
      toast.error(error.message);
    },
  });

  const suggestion = emailSpellChecker.run({
    email: values.email || '',
  });

  React.useEffect(() => {
    document.title = 'Carrier Sign Up | Create Account';
  }, []);

  const handleShowVerifyEmail = () => {
    setShowVerifyEmail(true);
    onUpdateTitle('Confirm Email');
  };

  return (
    <Wrapper>
      {showVerifyEmail ? (
        <VerifyEmail
          sniperLink={sniperLink}
          onBack={() => {
            setShowVerifyEmail(false);
            onUpdateTitle('Create Account');
            setPreviousEmail(values.email);
          }}
        />
      ) : (
        <>
          <FormGroup>
            <Field name="email" validate={validations.email}>
              {({ input, meta }) => (
                <FieldWrapper>
                  <TextField
                    {...input}
                    label="Email"
                    maxLength={255}
                    onChange={(e) => {
                      input.onChange(e);
                      setSendVerificationCodeError(undefined);
                    }}
                    errorMessage={
                      errors(meta) ||
                      submitErrors?.email ||
                      sendVerificationCodeError
                    }
                  />
                  {sendVerificationCodeError && (
                    <HelperText>
                      If you have an account,{' '}
                      <Link
                        href={
                          import.meta.env.VITE_TARGET === 'production'
                            ? 'https://carrier.superdispatch.com/tms/login/'
                            : 'https://staging.carrier.superdispatch.org/tms/login'
                        }
                      >
                        log in instead
                      </Link>
                    </HelperText>
                  )}
                  {suggestion && !(errors(meta) || submitErrors?.email) && (
                    <HelperText>
                      Do you mean{' '}
                      <Link
                        onClick={(e) => {
                          e.preventDefault();
                          input.onChange(suggestion.full);
                        }}
                      >
                        {' '}
                        {suggestion.domain}
                      </Link>
                      ?
                    </HelperText>
                  )}
                </FieldWrapper>
              )}
            </Field>
          </FormGroup>

          <FormGroup>
            <Field name="password" maxLength={255} validate={validatePassword}>
              {({ input, meta }) => (
                <PasswordField
                  {...input}
                  label="Password"
                  placeholder="Enter 8 characters or more"
                  errorMessage={!!meta.error && isSubmitFailed && ' '}
                />
              )}
            </Field>
          </FormGroup>

          <PasswordStrength value={values.password} />

          <Wrapper>
            <Button
              size="large"
              onClick={(e) => {
                if (valid) {
                  e.preventDefault();
                  if (values.email !== previousEmail) {
                    sendVerificationCodeMutation.mutate(values.email);
                  } else {
                    handleShowVerifyEmail();
                  }
                }
              }}
              isLoading={sendVerificationCodeMutation.isLoading}
              disabled={!valid}
            >
              Continue <StyledArrowForwardIcon />
            </Button>
          </Wrapper>
          <TermsOfService />
        </>
      )}
    </Wrapper>
  );
}
