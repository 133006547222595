import { Radio } from '@sdkit/radio';
import * as React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div``;
const RadioGroupWrapper = styled.div<{
  column?: boolean;
  columnOnMobile?: boolean;
}>`
  display: flex;
  & > *:not(:last-child) {
    margin-right: 15px;
  }
  & > * > span:nth-child(2) {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
  }
  & > * > span > span {
    width: 6px !important;
    height: 6px !important;
    background: #ffffff;
  }
  ${({ column }) =>
    column &&
    css`
      flex-direction: column;
      line-height: 34px;
    `};
  ${({ columnOnMobile }) =>
    columnOnMobile &&
    css`
      @media (max-width: 768px) {
        flex-direction: column;
        line-height: 34px;
      }
    `};
`;
const Label = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  padding-bottom: 0.35em;
`;
const ErrorMessage = styled.div`
  color: #ec310b;
  margin-top: 0.35em;
  display: block;
`;

interface RadioGroupProps {
  label: string;
  checked?: number;
  values: Array<{ label: string; value: any }>;
  name: string;
  column?: boolean;
  columnOnMobile?: boolean;
  errorMessage: React.ReactNode;
  onClick: (value: any) => void;
}

export function RadioGroupField({
  values,
  name,
  checked,
  onClick,
  column,
  columnOnMobile,
  label,
  errorMessage,
}: RadioGroupProps) {
  return (
    <Wrapper>
      <Label>{label}</Label>
      <RadioGroupWrapper column={column} columnOnMobile={columnOnMobile}>
        {values.map((item, key) => (
          <Radio
            key={key}
            defaultChecked={checked === key}
            name={name}
            label={item.label}
            onClick={() => {
              onClick(item.value);
            }}
          />
        ))}
      </RadioGroupWrapper>
      <ErrorMessage>{errorMessage}</ErrorMessage>
    </Wrapper>
  );
}
