import { Button as SDKitButton, ButtonProps } from '@sdkit/button';
import styled from 'styled-components';

const StyledButton = styled(SDKitButton)`
  height: 56px;
  width: 100%;
  & > span {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.35px;
  }
  &:not(.sdkit-button-loading) > span {
    position: relative;
    top: -2px;
    & > svg {
      position: relative;
      top: 2px;
    }
  }
`;

export function Button({ ...props }: ButtonProps) {
  return <StyledButton color={SDKitButton.PRIMARY} {...props} />;
}
