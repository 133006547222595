import { useShipperPublicFeatureToggle } from '../shared/useShipperPublicFeatureToggle';
import { SignupShipper } from './SignupShipper';
import { SignupShipper as SignupShipperCardDetailsExperiment } from './SignupShipperCardDetailsExperiment/SignupShipperCardDetailsExperiment';

export function SignupShipperPage() {
  const [shouldRedirect, isFeatureLoading] = useShipperPublicFeatureToggle(
    'signup.redirect-shipper.ui',
  );

  if (isFeatureLoading) {
    return null;
  }

  if (shouldRedirect) {
    return <RedirectToMarketingSite />;
  }

  return <InternalSignupShipperPage />;
}

function RedirectToMarketingSite() {
  window.location.replace('https://superdispatch.com/shipper-request-demo');
  return null;
}

function InternalSignupShipperPage() {
  const [
    isCardDetailsExperimentsEnabled,
    isCardDetailsExperimentsFeatureFlagLoading,
  ] = useShipperPublicFeatureToggle(
    'website-signup.shipper.cc-details-experiment.ui',
  );

  if (isCardDetailsExperimentsFeatureFlagLoading) {
    return null;
  }

  return isCardDetailsExperimentsEnabled ? (
    <SignupShipperCardDetailsExperiment />
  ) : (
    <SignupShipper />
  );
}
