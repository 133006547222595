import * as React from 'react';
import PhoneInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import styled, { css } from 'styled-components';

// eslint-disable-next-line @typescript-eslint/naming-convention
function _uuid(
  length = 16,
  chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
) {
  let result = '';
  for (let i = length; i > 0; --i) {
    result += chars[Math.floor(Math.random() * chars.length)];
  }
  return result;
}
const INPUT_ID = _uuid();
const INPUT_CLASS_NAME = _uuid();

const paddingsOverrideStyles = css`
  ${[1, 2, 3, 4, 5].reduce(
    (styles, i) =>
      `${styles}
  .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-${i} input,
  .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-${i} input[type='tel'],
  .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-${i} input[type='text'] {
    padding-left: ${['90px', '105px', '113px', '123px', '130px'][i - 1]};
  }
`,
    '',
  )}
`;
const PhoneInputWrapper = styled.div<{
  error?: boolean;
  separateDialCode?: boolean;
}>`
  display: flex;
  flex-direction: column;

  .intl-tel-input.allow-dropdown .selected-flag {
    padding: 0px 6px 0 15px;
    width: 70px;
  }
  .intl-tel-input .selected-flag .iti-arrow {
    top: unset;
    ${({ separateDialCode }) =>
      separateDialCode &&
      css`
        position: unset;
      `};
    margin: unset;
    border: 0;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEuNDEgMEw2IDQuNThMMTAuNTkgMEwxMiAxLjQxTDYgNy40MUwwIDEuNDFMMS40MSAwWiIgZmlsbD0iIzZBNzA3QyIvPjwvc3ZnPg==);
    width: 24px;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    transition: 0.2s ease-in-out;
  }
  .intl-tel-input .selected-flag .iti-arrow.up {
    transform: scale(-1);
  }
  .intl-tel-input.allow-dropdown.separate-dial-code .selected-flag {
    background-color: unset;
  }
  .intl-tel-input.allow-dropdown input.${INPUT_CLASS_NAME} {
    padding-left: 73px;
    height: 2.28em;
    width: 100%;
    height: 56px;
    display: block;
    outline: none;
    -webkit-transition: border-color 300ms;
    transition: border-color 300ms;
    font-size: inherit;
    line-height: inherit;
    border: 1px solid #c4cdd5;
    border-radius: 4px;
    background-color: #ffffff;
    box-sizing: border-box;
    -webkit-appearance: none;
    &:focus {
      border: 2px solid #0075ff;
    }
    ${({ error }) =>
      error &&
      css`
        &,
        &:focus {
          border: 2px solid #ec310b;
        }
      `};
  }
  ${({ separateDialCode }) => separateDialCode && paddingsOverrideStyles};
`;
const PhoneInputLabel = styled.label`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  padding-bottom: 0.35em;
`;
const PhoneInputErrorLabel = styled.span`
  color: #ec310b;
  margin-top: 6px;
`;

// just for readme
class IntlTelInput {
  containerClassName? = 'intl-tel-input';
  inputClassName? = 'phone-input';
  fieldName? = '';
  fieldId? = '';
  defaultValue? = '';
  countriesData?: any[] | null = null;
  allowDropdown? = true;
  autoHideDialCode? = true;
  autoPlaceholder? = true;
  customPlaceholder?: (() => string) | null = null;
  excludeCountries?: string[] = [];
  formatOnInit? = true;
  separateDialCode? = false;
  defaultCountry? = '';
  geoIpLookup?: (() => any) | null = null;
  nationalMode? = true;
  numberType? = 'MOBILE';
  noCountryDataHandler?: (() => any) | null = null;
  onlyCountries?: string[] = [];
  preferredCountries?: string[] = ['us', 'ca', 'nz'];
  onPhoneNumberChange?: (() => any) | null = null;
  onPhoneNumberBlur?: (() => any) | null = null;
  onSelectFlag?: (() => any) | null = null;
  disabled? = false;
  autoFocus? = false;
  useMobileFullscreenDropdown? = true;
  autoComplete? = 'off';
  telInputProps?: any = {};
  format? = false;
  onFlagClick?: (() => any) | null = null;
  value?: { iso2: string; dialCode: string; phone: string };
  placeholder?: string;
  style?: React.CSSProperties;
}

interface PhoneTextFieldProps extends IntlTelInput {
  label?: string;
  errorMessage?: string | React.ReactNode;
  name?: string;
  type?: string;
  [key: string]: any;
}

export const PhoneField = ({
  errorMessage,
  separateDialCode,
  label,
  value,
  onBlur,
  onChange,
  onFocus,
  name,
  placeholder,
}: PhoneTextFieldProps) => (
  <PhoneInputWrapper
    error={Boolean(errorMessage)}
    separateDialCode={separateDialCode}
  >
    <PhoneInputLabel htmlFor={INPUT_ID}>{label}</PhoneInputLabel>
    <PhoneInput
      onPhoneNumberChange={(
        _: boolean,
        phone: string,
        { iso2, dialCode }: any,
      ) => {
        onChange({ iso2, dialCode, phone });
      }}
      onPhoneNumberBlur={onBlur}
      telInputProps={{
        onClick: onFocus,
      }}
      onSelectFlag={(phone: string, { iso2, dialCode }: any) => {
        onChange({ iso2, dialCode, phone });
      }}
      // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
      value={value && value.phone}
      // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
      defaultCountry={value && value.iso2}
      fieldId={INPUT_ID}
      fieldName={name}
      placeholder={placeholder}
      inputClassName={INPUT_CLASS_NAME}
      separateDialCode={separateDialCode}
      preferredCountries={['us', 'ca', 'nz']}
      autoComplete="phone"
      format={true}
    />
    {errorMessage && (
      <PhoneInputErrorLabel>{errorMessage}</PhoneInputErrorLabel>
    )}
  </PhoneInputWrapper>
);
