import * as React from 'react';
import { useEffect } from 'react';
import { useField, UseFieldConfig } from 'react-final-form';
import { Autocomplete, AutocompleteProps } from '../ui/Autocomplete';
import { CanadianProvincesOptions } from './CanadianProvinces';
import { USAStatesOptions } from './USAStates';

interface StateAutocompleteFieldProps
  extends UseFieldConfig<string, string>,
    Omit<
      AutocompleteProps,
      'value' | 'type' | 'defaultValue' | 'onChange' | 'options'
    > {
  name: string;
  country: string;
}

export function StateAutocompleteField({
  country,
  name,
  validate,
  ...props
}: StateAutocompleteFieldProps) {
  const {
    input: { value, onChange },
    meta,
  } = useField(name, { validate });

  useEffect(() => {
    onChange('');
  }, [country, onChange]);

  return (
    <Autocomplete
      {...props}
      value={value}
      onChange={onChange}
      errorMessage={meta.error && meta.touched && <span>{meta.error}</span>}
      options={country === 'US' ? USAStatesOptions : CanadianProvincesOptions}
    />
  );
}
