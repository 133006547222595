import styled from 'styled-components';

const StyledRoot = styled.div`
  background-color: rgba(243, 245, 248, 1);
  padding: 16px;
  border-radius: 4px;
  text-align: center;
`;

const StyledPlanName = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
`;

const StyledTotalDueLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 8px;
`;

const StyledPrice = styled.div`
  font-size: 48px;
  font-weight: 600;
  line-height: 54px;
  letter-spacing: 0.3499999940395355px;
  margin-top: 4px;
`;

const StyledNotice = styled.div`
  color: rgba(91, 99, 113, 1);
  margin-top: 10px;
`;

export function PaymentDetailsPlanCard() {
  const trialEndDate = new Date().setDate(new Date().getDate() + 14);
  const trialEndDateFormatted = new Intl.DateTimeFormat('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  }).format(trialEndDate);

  return (
    <StyledRoot>
      <StyledPlanName>Shipper Express 50 VINs/mo</StyledPlanName>

      <StyledTotalDueLabel>Total Due Today</StyledTotalDueLabel>

      <StyledPrice>0$</StyledPrice>

      <StyledNotice>
        If you do not cancel the trial, starting {trialEndDateFormatted}, you
        will be charged $129/mo + applicable tax.
      </StyledNotice>
    </StyledRoot>
  );
}
