import { TextField as SDKitTextField, TextFieldProps } from '@sdkit/textfield';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { HelpTooltip } from './HelpTooltip';

const StyledTextField = styled(SDKitTextField)`
  height: 56px;
  border: 1px solid #c4cdd5;
  border-radius: 4px;
`;

const StyledTextFieldLabel = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  display: flex;
  gap: 4px;
  align-items: center;
`;

export function TextField({
  label,
  tooltipContent,
  ...props
}: TextFieldProps & { tooltipContent?: ReactNode }) {
  return (
    <StyledTextField
      {...props}
      label={
        <StyledTextFieldLabel>
          {label}

          {tooltipContent && <HelpTooltip content={tooltipContent} />}
        </StyledTextFieldLabel>
      }
    />
  );
}
