import styled from 'styled-components';

export const PageTitle = styled.h1`
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  margin: 0;
`;

export const PageSubtitle = styled.h2`
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  margin: 16px 0 0;
`;
