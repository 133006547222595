import { ReactNode } from 'react';
import styled from 'styled-components';
import InfoIcon from '../assets/info.svg?react';
import { Tooltip } from './Tooltip';

const StyledInfoIcon = styled(InfoIcon)`
  color: rgba(143, 148, 158, 1);
`;

const StyledButton = styled.button`
  position: relative;
  background: none;
  border: none;
  padding: 0;
  display: flex;

  &:before {
    content: '';
    position: absolute;
    inset: -4px;
  }
`;

export function HelpTooltip({ content }: { content: ReactNode }) {
  return (
    <Tooltip content={content}>
      <StyledButton>
        <StyledInfoIcon />
      </StyledButton>
    </Tooltip>
  );
}
