import { Checkbox } from '@sdkit/checkbox';
import * as React from 'react';
import { Field, FieldMetaState, useFormState } from 'react-final-form';
import styled from 'styled-components';
import ArrowForwardIcon from '../../shared/assets/arrow-forward.svg?react';
import {
  composeValidators,
  disallowHtml,
  isValidCompanyName,
  isValidName,
  isValidPhone,
} from '../../shared/helpers/validationHelpers';
import { Button } from '../../shared/ui/Button';
import { FormGroup } from '../../shared/ui/FormGroup';
import { PhoneField } from '../../shared/ui/PhoneField';
import { TextField } from '../../shared/ui/TextField';

const Wrapper = styled.div`
  margin-top: 20px;
`;
const StyledArrowForwardIcon = styled(ArrowForwardIcon)`
  margin-left: 10px;
`;

const validations = {
  firsName: composeValidators([
    isValidName('Please enter a first name'),
    disallowHtml(),
  ]),
  last_name: composeValidators([
    isValidName('Please enter a last name'),
    disallowHtml(),
  ]),
  phone: isValidPhone('Please enter a valid phone number'),
  company_name: composeValidators([
    isValidCompanyName('Enter a company name'),
    disallowHtml(),
  ]),
};

const errors = (meta: FieldMetaState<unknown>) => {
  if (meta.error && meta.touched) {
    return <span>{meta.error}</span>;
  }
  if (meta.submitError && !meta.touched) {
    return <span>{meta.submitError}</span>;
  }
};

export function ContactInfo() {
  const { submitErrors } = useFormState();

  React.useEffect(() => {
    document.title = 'Carrier Sign Up | Contact Info';
  }, []);

  return (
    <Wrapper>
      <FormGroup>
        <Field name="company_name" validate={validations.company_name}>
          {({ input, meta }) => (
            <TextField
              {...input}
              label="Company Name"
              autoFocus={!submitErrors}
              errorMessage={errors(meta)}
              maxLength={255}
            />
          )}
        </Field>
      </FormGroup>
      <FormGroup>
        <Field name="first_name" validate={validations.firsName}>
          {({ input, meta }) => (
            <TextField
              {...input}
              label="First Name"
              errorMessage={errors(meta)}
              maxLength={255}
            />
          )}
        </Field>
        <Field name="last_name" validate={validations.last_name}>
          {({ input, meta }) => (
            <TextField
              {...input}
              label="Last Name"
              errorMessage={errors(meta)}
              maxLength={255}
            />
          )}
        </Field>
      </FormGroup>

      <FormGroup>
        <Field
          name="phoneNumber"
          validate={(value) =>
            validations.phone(`+${value.dialCode}${value.phone}`, value.iso2)
          }
        >
          {({ input, meta }) => (
            <PhoneField
              {...input}
              placeholder="Your phone number"
              label="Phone Number"
              separateDialCode={true}
              errorMessage={errors(meta) || submitErrors?.phone_number}
            />
          )}
        </Field>
      </FormGroup>

      <FormGroup>
        <Field type="checkbox" name="subscriptions">
          {({ input }) => (
            <Checkbox
              {...input}
              label="I agree to receive SMS and Email messages from Super Dispatch such as the latest offers and deals."
            />
          )}
        </Field>
      </FormGroup>

      <Button size="large" type="submit">
        Continue <StyledArrowForwardIcon />
      </Button>
    </Wrapper>
  );
}
