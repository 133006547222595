import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
`;
export const ContentLeft = styled.div`
  flex: 1 1 0;
`;
export const ContentLeftInner = styled.div`
  margin: 8% 10% 10% 40%;
  @media (max-width: 1024px) {
    margin: 5%;
  }
`;
export const ContentRight = styled.div`
  background: #f3f5f8;
  box-sizing: border-box;
  flex: 1 1 0;
  z-index: 1;
  @media (max-width: 1024px) {
    display: none;
  }
`;
export const ContentRightInner = styled.div`
  margin: 120px 27% 10% 10%;
`;
