import { parsePhoneNumberFromString } from 'libphonenumber-js';

export function composeValidators(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validators: Array<(value: any) => string | undefined>,
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (value: any) => {
    return validators.reduce<string | undefined>((error, validator) => {
      if (error) {
        return error;
      }

      return validator(value);
    }, undefined);
  };
}

export const required =
  (message = 'This field is required') =>
  (value = '') =>
    value !== '' ? undefined : message;

export const disallowHtml =
  (message = 'Please remove special characters') =>
  (value = '') => {
    if (/<[^>]+>/g.test(value)) {
      return message;
    }
    return undefined;
  };

export const isValidCompanyName =
  (message = 'Enter a company name') =>
  (value = '') => {
    const cleanedName = value.trim().replace(' ', '');
    if (cleanedName === '') {
      return message;
    }

    if (cleanedName.length > 50) {
      return 'Please enter a name less than 50 characters';
    }
    return undefined;
  };

export const isValidPhone =
  (message = 'Please enter a correct phone number') =>
  (value = '', country: string | undefined) => {
    const phoneNumber = parsePhoneNumberFromString(
      value,
      country?.toUpperCase() as any,
    );

    try {
      return phoneNumber?.isValid() ? undefined : message;
    } catch (e: any) {
      return message;
    }
  };

export const onlyLetters =
  (message = 'Please enter a correct input') =>
  (value = '') =>
    /^[a-zA-Z]+$/.test(value) ? undefined : message;

export const isValidEmail =
  (message = 'Please enter a correct email') =>
  (value = '') =>
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value,
    )
      ? undefined
      : message;

export const isValidName =
  (message: string) =>
  (value = '') => {
    const cleanedName = value.trim().replace(' ', '');

    if (cleanedName === '') {
      return message;
    }

    if (cleanedName.length > 40) {
      return 'Please enter a name less than 40 characters';
    }

    if (/-{2}/g.test(cleanedName)) {
      return 'Please avoid using consecutive hyphen';
    }

    if (
      /'{2}/g.test(cleanedName) ||
      /"{2}/g.test(cleanedName) ||
      /`{2}/g.test(cleanedName)
    ) {
      return 'Please avoid using consecutive apostrophes';
    }

    if (/\.{2}/g.test(cleanedName)) {
      return 'Please avoid using consecutive period';
    }

    if (/(\d)\1/.test(cleanedName)) {
      return 'Please avoid using repeating numbers';
    }

    if (/(.)\1{3,}/g.test(cleanedName)) {
      return 'Please avoid using repeating characters up to 3 times';
    }

    return undefined;
  };

export const isSelected =
  (values: any[], message = 'Please choose one of the options') =>
  (value: any) =>
    values.includes(value) ? undefined : message;

export function validatePassword(value: string) {
  if (
    !value ||
    !validateToPasswordLength(value) ||
    !validateToUpperAndLowerCase(value) ||
    !validateToSpecailCharacters(value) ||
    !validateToNumber(value)
  ) {
    return 'Wrong Password';
  }
  return undefined;
}

export const passwordCheckTypes = {
  toNumber: /(?=.*[0-9])/,
  toLowerCaseAndUpperCase: /^(?=.*[a-z])(?=.*[A-Z]).+$/,
  toSpecialCharacter: /[!@#$%^&*()_+\-={[}\]|\\;:'"<>?,.]/,
  matchSpecialCharacter: /[!@#$%^&*()_+\-={[}\]|\\;:'"<>?,.]/g,
};

// if there would pass all validation, more than 11 length of password and consists of more than one special character, count will be increased. It means that it is strong password here. On other other cases if just past all validation, it is good password only
export function validatePasswordStrength(value?: string) {
  let count = 6;
  if (!value) {
    count -= 1;
  }
  if (!validateToPasswordLength(value)) {
    count -= 1;
  }
  if (!validateToUpperAndLowerCase(value)) {
    count -= 1;
  }
  if (!validateToSpecailCharacters(value)) {
    count -= 1;
  }
  if (!validateToNumber(value)) {
    count -= 1;
  }
  if (
    value &&
    validateToNumber(value) &&
    validateToSpecailCharacters(value) &&
    validateToUpperAndLowerCase(value) &&
    value.trim().length > 11 &&
    checkSpecialCharactersLength(value)
  ) {
    count += 1;
  }
  return count;
}

export function validateToNumber(text?: string) {
  return !!text && passwordCheckTypes.toNumber.test(text);
}

export function validateToUpperAndLowerCase(text?: string) {
  return !!text && passwordCheckTypes.toLowerCaseAndUpperCase.test(text);
}

export function validateToSpecailCharacters(text?: string) {
  return !!text && passwordCheckTypes.toSpecialCharacter.test(text);
}

export function checkSpecialCharactersLength(text: string) {
  const charactersList = text.match(passwordCheckTypes.matchSpecialCharacter);
  return charactersList && charactersList.length > 1;
}

export function validateToPasswordLength(text?: string) {
  return !!text && text.trim().length > 7;
}
