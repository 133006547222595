import styled from 'styled-components';
import { openSupportChat } from '../../shared/helpers/intercomHelpers';
import { BackendValidation } from './backendValidation';

const ErrorMessage = styled.div`
  margin: 20px 0;
  padding: 16px;
  background: #fff7dc;
`;

export function BackendValidationErrors({
  backendValidation,
}: {
  backendValidation: BackendValidation;
}) {
  const { data } = backendValidation;

  if (data.error_id === 'CHARGEBEE_PAYMENT_METHOD_ERROR') {
    return <ErrorMessage>{data.message}</ErrorMessage>;
  }

  if (data.error_id === 'SHIPPER_NAME_ALREADY_EXISTS') {
    return (
      <ErrorMessage>
        The company name you entered is already in use. If you&apos;re part of
        this company, please{' '}
        <a href="https://shipper.superdispatch.com/signin">log in</a> or{' '}
        <a
          href="#"
          onClick={(event) => {
            event.preventDefault();
            openSupportChat();
          }}
        >
          contact support
        </a>{' '}
        for help with your account.
      </ErrorMessage>
    );
  }

  if (data.error_id === 'USERNAME_ALREADY_EXISTS') {
    return (
      <ErrorMessage>
        The email you entered is already in use. If it&apos;s yours,{' '}
        <a href="https://shipper.superdispatch.com/signin">log in</a> or{' '}
        <a
          href="#"
          onClick={(event) => {
            event.preventDefault();
            openSupportChat();
          }}
        >
          contact support
        </a>{' '}
        to reset password.
      </ErrorMessage>
    );
  }

  if (data.details) {
    const detailsEntries = Object.entries(data.details);

    return (
      <ErrorMessage>
        {detailsEntries.map(([fieldName, errorMessage]) => (
          <div key={fieldName}>{errorMessage}</div>
        ))}
      </ErrorMessage>
    );
  }

  return <ErrorMessage>{data.message}</ErrorMessage>;
}
