import { initializeUserMonitor } from './datadog';

const checkIntercomInit = () => {
  if ((window as any).Intercom) {
    (window as any).Intercom('boot', {
      app_id: 'rpk7bg8e',
    });
    return;
  }
  setTimeout(checkIntercomInit, 1000);
};

export function bootstrap() {
  void initializeUserMonitor();
  checkIntercomInit();
}
