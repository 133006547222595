function paramsToObject(entries: Array<[string, string]>) {
  const result: Record<string, string> = {};
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
}

export function parseSearchParams(search: string) {
  const urlParams = new URLSearchParams(search);
  const entries = urlParams.entries();
  return paramsToObject(Array.from(entries));
}
