import ChargebeeComponents from '@chargebee/chargebee-js-react-wrapper/dist/components/ComponentGroup';
import createDecorator from 'final-form-focus';
import * as React from 'react';
import { useState } from 'react';
import { Form } from 'react-final-form';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';
import { Modal } from '../../core/Modal';
import { TermsOfService } from '../../core/TermsOfService';
import { SignupShipperData } from '../../data/DTOs';
import { signupShipperWithPaymentInfo } from '../../data/ShipperAPI';
import { trackEvent, trackPage } from '../../shared/helpers/AnalyticsHelpers';
import { openSupportChat } from '../../shared/helpers/intercomHelpers';
import { Header } from '../../shared/layout/Header';
import { Button } from '../../shared/ui/Button';
import { Stepper } from '../../shared/ui/Stepper';
import { captureError, logInfo, logWarning } from '../../shared/utils/datadog';
import { AccountInformationFields } from './AccountInformationFields';
import {
  BackendValidation,
  checkHasAccountInformationBackendValidationErrors,
  parseBackendValidation,
} from './backendValidation';
import { BackendValidationErrors } from './BackendValidationErrors';
import { PaymentDetailsFields } from './PaymentDetailsFields';
import {
  checkHasAccountInformationErrors,
  checkHasPaymentInformationErrors,
  identifyUserOnSegment,
  redirectToShipperTMS,
  sendFivetranWebhookEvent,
  SignupShipperValidationErrors,
  trackSignedUpEvent,
} from './utils';

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
const focusOnErrors = createDecorator();

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledContent = styled.div`
  max-width: 660px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 64px 24px;
`;

const StyledStepperContainer = styled.div`
  margin-bottom: 40px;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ThankYouText = styled.div`
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 0.35px;
  font-weight: 600;
  margin-bottom: 16px;
  margin-top: 32px;
  max-width: 300px;
`;

const Message = styled.div`
  color: #6a707c;
  text-align: center;
  font-size: 18px;
`;

const StyledForm = styled.form`
  display: inline-block;
`;

const SubmitButtonAndTermsContainer = styled.div`
  max-width: 448px;
`;

const StyledStepperContent = styled.div<{ active: boolean }>`
  display: ${(props) => (props.active ? 'block' : 'none')};
`;

export function SignupShipper() {
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const [isShowSuccessModal, setShowSuccessModal] = React.useState(false);
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [isCardValid, setIsCardValid] = React.useState(false);

  const isCaptchaDisabled =
    import.meta.env.VITE_TARGET !== 'production' &&
    localStorage.getItem('staging-shipper-signup-captcha-disabled') === 'true';
  const isCaptchaValid = captchaToken !== null || isCaptchaDisabled;
  const [backendValidation, setBackendValidation] =
    React.useState<BackendValidation | null>(null);

  const cardRef = React.useRef<ChargebeeComponents | null>(null);

  React.useEffect(() => {
    document.title = 'Shipper Sign Up';
    trackPage('Shipper Sign Up');
  }, []);

  function handleBackendValidationError(error: unknown) {
    parseBackendValidation(error)
      .then((newBackendValidation) => {
        setBackendValidation(newBackendValidation);

        if (
          checkHasAccountInformationBackendValidationErrors(
            newBackendValidation,
          )
        ) {
          setCurrentStepIndex(0);
        }
      })
      .catch(() => {
        // eslint-disable-next-line no-alert
        alert('Something went wrong! Please contact support!');
        openSupportChat();
      });
  }

  function handleNonSelfServeSignup(shipperData: SignupShipperData) {
    identifyUserOnSegment(shipperData.email, undefined, shipperData, true);
    trackSignedUpEvent(shipperData.companyType);
    setShowSuccessModal(true);
  }

  async function handleServeServeSignup(shipperData: SignupShipperData) {
    setSubmitting(true);

    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { token: chargebeeToken }: { token: string } =
        await cardRef.current?.tokenize({});

      const { broker_url, token, user_guid, shipper_guid } =
        await signupShipperWithPaymentInfo(shipperData, chargebeeToken);

      identifyUserOnSegment(user_guid, shipper_guid, shipperData);
      trackSignedUpEvent(shipperData.companyType, true);
      redirectToShipperTMS(broker_url, token);
    } catch (error) {
      captureError(error, 'ShipperSignup#submit', shipperData);

      handleBackendValidationError(error);
    } finally {
      setSubmitting(false);
    }
  }

  const handleOnSubmit = async (shipperData: SignupShipperData) => {
    if (isSubmitting) {
      return;
    }

    if (!isCaptchaValid) {
      logWarning('Shipper missing Captcha token', shipperData);
      // eslint-disable-next-line no-alert
      alert('Failed to verify Captcha. Please try again.');
      return;
    }

    trackEvent('Sign up submitted', {
      companyType: shipperData.companyType,
    });

    logInfo('Shipper Sign up submitted', {
      ...shipperData,
      password: null,
    });

    const isSelfServe = shipperData.carsPerMonth <= 50;

    if (import.meta.env.VITE_TARGET === 'production') {
      await sendFivetranWebhookEvent(shipperData);
    }

    if (!isSelfServe) {
      handleNonSelfServeSignup(shipperData);
      return;
    }

    void handleServeServeSignup(shipperData);
  };

  return (
    <>
      <StyledRoot>
        <Header type="shipper" />

        <StyledContent>
          <ContentEnterAnimation>
            <Form
              initialValues={{
                firstName: '',
                lastName: '',
                companyName: '',
                email: '',
                phoneNumber: { iso2: 'us', dialCode: '1', phone: '' },
                companyType: '',
                carsPerMonth: 1,
                comment: '',
                subscriptions: false,
                referralSource: '',
                country: 'US',
                state: '',
                password: '',
                paymentInfo: {
                  country: 'US',
                },
              }}
              keepDirtyOnReinitialize={true}
              onSubmit={handleOnSubmit}
              decorators={[focusOnErrors]}
              render={({ handleSubmit, values, errors }) => {
                const hasAccountInformationErrors =
                  checkHasAccountInformationErrors(
                    errors as SignupShipperValidationErrors,
                  );
                const hasPaymentDetailsErrors =
                  checkHasPaymentInformationErrors(
                    errors as SignupShipperValidationErrors,
                  );

                return (
                  <>
                    {values.carsPerMonth <= 50 && (
                      <StyledStepperContainer>
                        <Stepper
                          currentStepIndex={currentStepIndex}
                          steps={['Account Information', 'Payment Information']}
                          onStepChange={setCurrentStepIndex}
                        />
                      </StyledStepperContainer>
                    )}

                    <StyledForm onSubmit={handleSubmit}>
                      <StyledStepperContent active={currentStepIndex === 0}>
                        <AccountInformationFields
                          values={values}
                          onCaptchaTokenChange={setCaptchaToken}
                        />
                      </StyledStepperContent>

                      {values.carsPerMonth <= 50 && (
                        <StyledStepperContent active={currentStepIndex === 1}>
                          <PaymentDetailsFields
                            values={values}
                            cardRef={cardRef}
                            onCardValidation={setIsCardValid}
                          />
                        </StyledStepperContent>
                      )}

                      {backendValidation && (
                        <BackendValidationErrors
                          backendValidation={backendValidation}
                        />
                      )}

                      <SubmitButtonAndTermsContainer>
                        {currentStepIndex === 0 && values.carsPerMonth > 50 && (
                          <Button
                            type="submit"
                            disabled={
                              hasAccountInformationErrors || !isCaptchaValid
                            }
                            isLoading={isSubmitting}
                          >
                            Get Started Now
                          </Button>
                        )}

                        {currentStepIndex === 0 && values.carsPerMonth <= 50 && (
                          <Button
                            type="button"
                            disabled={
                              hasAccountInformationErrors || !isCaptchaValid
                            }
                            onClick={() => {
                              setCurrentStepIndex(1);
                            }}
                          >
                            Get Started Now
                          </Button>
                        )}

                        {currentStepIndex === 1 && (
                          <Button
                            type="submit"
                            isLoading={isSubmitting}
                            disabled={hasPaymentDetailsErrors || !isCardValid}
                          >
                            Start 14-day Free Trial
                          </Button>
                        )}

                        <TermsOfService />
                      </SubmitButtonAndTermsContainer>
                    </StyledForm>
                  </>
                );
              }}
            />
          </ContentEnterAnimation>
        </StyledContent>
      </StyledRoot>

      {isShowSuccessModal && <SuccessDialog />}
    </>
  );
}

function SuccessDialog() {
  return (
    <Modal
      body={
        <ModalBody>
          <ThankYouText>Thanks for trying out Super Dispatch!</ThankYouText>
          <Message>Check your email for next steps.</Message>
        </ModalBody>
      }
    />
  );
}

function ContentEnterAnimation({ children }: { children: React.ReactNode }) {
  const slide = useSpring({
    from: { transform: 'translateX(100%)' },
    to: { transform: 'translateX(0%)' },
  });

  return (
    <animated.div style={window.innerWidth > 1024 ? slide : {}}>
      {children}
    </animated.div>
  );
}
