export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i.test(
    navigator.userAgent,
  );
}

export function getUserTimezone(defaultTimezone = 'America/Chicago') {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (e: unknown) {
    return defaultTimezone;
  }
}
