import { AutoComplete as SDKitAutoComplete } from '@sdkit/autocomplete';
import { TextFieldProps } from '@sdkit/textfield';
import * as React from 'react';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { TextField } from './TextField';

export interface AutocompleteProps extends Omit<TextFieldProps, 'onChange'> {
  onChange: (value: string) => void;
  options: Array<{ text: string; value: string }>;
}

export function Autocomplete({
  value,
  options,
  onChange,
  ...rest
}: AutocompleteProps) {
  const [inputValue, $inputValue] = useState<string>('');

  const syncInputValue = useCallback(() => {
    const option = options.find((x) => x.value === value);

    if (option) {
      $inputValue(option.text);
    } else {
      $inputValue('');
    }
  }, [options, value]);

  useEffect(() => {
    syncInputValue();
  }, [value, options, syncInputValue]);

  return (
    <SDKitAutoComplete
      optionTitle={(item) => item.text}
      onSelect={(item) => {
        const option = item as { text: string; value: string };
        onChange(option.value);
      }}
    >
      {(setSource) => {
        return (
          <TextField
            {...rest}
            value={inputValue}
            onFocus={() => {
              setSource(options);
            }}
            onBlur={() => {
              syncInputValue();
              setSource([]);
            }}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              const changeValue = event.target.value;
              $inputValue(changeValue);
              setSource(
                options.filter((x) =>
                  x.text.toLowerCase().includes(changeValue.toLowerCase()),
                ),
              );
            }}
          />
        );
      }}
    </SDKitAutoComplete>
  );
}
