import ChargebeeComponents from '@chargebee/chargebee-js-react-wrapper/dist/components/ComponentGroup';
import { Ref } from 'react';
import { Field, FieldMetaState } from 'react-final-form';
import styled from 'styled-components';
import { SignupShipperData } from '../../data/DTOs';
import CheckIcon from '../../shared/assets/ic-check.svg?react';
import ShieldIcon from '../../shared/assets/shield.svg?react';
import { ChargebeeCardComponent } from '../../shared/chargebee/ChargebeeCardComponent';
import { AutoCompleteField } from '../../shared/form/AutocompleteField';
import { countryOptions } from '../../shared/form/CountryOptions';
import { StateAutocompleteField } from '../../shared/form/StateAutocompleteField';
import { required } from '../../shared/helpers/validationHelpers';
import { FormGroup } from '../../shared/ui/FormGroup';
import { TextField } from '../../shared/ui/TextField';
import { PaymentDetailsPlanCard } from './PaymentDetailsPlanCard';
import { PageSubtitle, PageTitle } from './shared';

const StyledPageHeader = styled.div`
  margin-bottom: 32px;
`;

const StyledSectionTitle = styled.div`
  color: rgba(91, 99, 113, 1);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 16px;
`;

const StyledFeatures = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledFeature = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: rgba(91, 99, 113, 1);
`;

const StyledFieldsContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 32px;
`;

const StyledChargebeeCardComponentContainer = styled.div`
  margin-bottom: 32px;
`;

const StyledPlanCardContainer = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;
`;

// eslint-disable-next-line no-warning-comments
// @todo - replace old FormGroup with new styled component that is more simpler
const StyledFormGroup = styled.div`
  display: flex;
  gap: 15px;

  & > * {
    flex: 1;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const validations = {
  addressLine1: required('Please enter address'),
  city: required('Please enter city'),
  state: required('Please enter state'),
  zip: required('Please enter zip code'),
  country: required('Please enter Country'),
};

const renderErrorMessage = (meta: FieldMetaState<unknown>) => {
  if (!meta.error || !meta.touched) {
    return null;
  }

  return <span>{meta.error}</span>;
};

export function PaymentDetailsFields({
  values,
  cardRef,
  onCardValidation,
}: {
  values: SignupShipperData;
  cardRef: Ref<ChargebeeComponents>;
  onCardValidation: (isValid: boolean) => void;
}) {
  return (
    <>
      <StyledPageHeader>
        <PageTitle>Add Payment Information</PageTitle>
        <PageSubtitle>
          Get full access to Shipper Express free today!
        </PageSubtitle>
        You won&apos;t be charged until your free trial ends. You can cancel
        anytime.
      </StyledPageHeader>

      <StyledSectionTitle>Card Details</StyledSectionTitle>

      <StyledFeatures>
        <StyledFeature>
          <CheckIcon />
          We use industry-standard encryption and security protocols to
          safeguard your information.
        </StyledFeature>

        <StyledFeature>
          <ShieldIcon />
          We accept all major credit cards.
        </StyledFeature>
      </StyledFeatures>

      <StyledFieldsContainer>
        <StyledChargebeeCardComponentContainer>
          <ChargebeeCardComponent
            cardRef={cardRef}
            onValidation={onCardValidation}
          />
        </StyledChargebeeCardComponentContainer>

        <StyledSectionTitle>Billing Address</StyledSectionTitle>

        <FormGroup>
          <Field name="paymentInfo.address" validate={validations.addressLine1}>
            {({ input, meta }) => (
              <TextField
                {...input}
                label="Address Line 1"
                errorMessage={renderErrorMessage(meta)}
                maxLength={255}
              />
            )}
          </Field>
          <Field name="paymentInfo.addressSecondLine">
            {({ input, meta }) => (
              <TextField
                {...input}
                label="Address Line 2 (Optional)"
                errorMessage={renderErrorMessage(meta)}
                maxLength={255}
              />
            )}
          </Field>
        </FormGroup>

        <StyledFormGroup>
          <Field name="paymentInfo.city" validate={validations.city}>
            {({ input, meta }) => (
              <TextField
                {...input}
                label="City"
                errorMessage={renderErrorMessage(meta)}
                maxLength={255}
              />
            )}
          </Field>

          <StyledFormGroup>
            <StateAutocompleteField
              name="paymentInfo.state"
              placeholder="Billing state"
              label="State"
              country={values.country}
              validate={validations.state}
            />

            <Field name="paymentInfo.zip" validate={validations.zip}>
              {({ input, meta }) => (
                <TextField
                  {...input}
                  label="ZIP Code"
                  errorMessage={renderErrorMessage(meta)}
                  maxLength={10}
                  tooltipContent="Zip code of your card's billing address"
                />
              )}
            </Field>
          </StyledFormGroup>
        </StyledFormGroup>

        <FormGroup>
          <AutoCompleteField
            name="paymentInfo.country"
            label="Country"
            options={countryOptions}
            validate={validations.country}
          />
        </FormGroup>
      </StyledFieldsContainer>

      <StyledPlanCardContainer>
        <PaymentDetailsPlanCard />
      </StyledPlanCardContainer>
    </>
  );
}
