import { Checkbox } from '@sdkit/checkbox';
import { Field, FieldMetaState } from 'react-final-form';
import styled from 'styled-components';
import { SignupShipperData } from '../../data/DTOs';
import { AutoCompleteField } from '../../shared/form/AutocompleteField';
import { countryOptions } from '../../shared/form/CountryOptions';
import { StateAutocompleteField } from '../../shared/form/StateAutocompleteField';
import { CaptchaWidget } from '../../shared/helpers/CaptchaWidget';
import {
  composeValidators,
  disallowHtml,
  isSelected,
  isValidEmail,
  isValidPhone,
  required,
  validatePassword,
} from '../../shared/helpers/validationHelpers';
import { FormGroup } from '../../shared/ui/FormGroup';
import { PasswordField } from '../../shared/ui/PasswordField';
import { PasswordStrength } from '../../shared/ui/PasswordStrength';
import { PhoneField } from '../../shared/ui/PhoneField';
import { RadioGroupField } from '../../shared/ui/RadioGroupField';
import { SelectField } from '../../shared/ui/SelectField';
import { TextField } from '../../shared/ui/TextField';
import { PageSubtitle, PageTitle } from './shared';

const OptionalTextFieldLabel = styled.span`
  & > span {
    color: #6a707c;
    font-weight: normal;
  }
`;

const carsPerMonthOptions = [
  { label: '1-50', value: 1 },
  { label: '51-100', value: 51 },
  { label: '101-500', value: 101 },
  { label: '501-1,000', value: 501 },
  { label: '1,000+', value: 1000 },
  { label: '10,000+', value: 10000 },
];
const referralOptions = [
  'Google Search',
  'Email',
  'Social Media',
  'Events / Vendor Booth / Conference',
  'BOL',
  'Word of Mouth',
  'Other',
];
const validations = {
  firstName: composeValidators([
    required('Please enter a first name'),
    disallowHtml(),
  ]),
  lastName: composeValidators([
    required('Please enter a last name'),
    disallowHtml(),
  ]),
  companyName: composeValidators([
    required('Please enter a company name'),
    disallowHtml(),
  ]),
  country: required('Please enter Country'),
  companyType: required('Please enter Company type'),
  state: required('Please enter State'),
  email: isValidEmail('Please enter a valid email'),
  phone: isValidPhone('Please enter a valid phone number'),
  carsPerMonth: isSelected(carsPerMonthOptions.map((x) => x.value)),
};

const renderErrorMessage = (meta: FieldMetaState<unknown>) => {
  if (!meta.error || !meta.touched) {
    return null;
  }

  return <span>{meta.error}</span>;
};

const companyTypes = [
  'Online Marketplace',
  'Broker',
  'Dealer',
  'OEM',
  'Auction',
  'Rental',
  'Import/Export',
  'Financial Institution',
  'Wholesaler',
  'Uplifter',
];

const ConditionalField = ({
  when,
  is,
  children,
}: {
  when: string;
  is: string;
  children: React.ReactNode;
}) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
);

export function AccountInformationFields({
  values,
  onCaptchaTokenChange,
}: {
  values: SignupShipperData;
  onCaptchaTokenChange: (token: string | null) => void;
}) {
  const hasPasswordField = values.carsPerMonth <= 50;

  return (
    <>
      <PageTitle>Get Started using Super Dispatch</PageTitle>
      <PageSubtitle>Begin your free trial today</PageSubtitle>

      <FormGroup>
        <Field name="firstName" validate={validations.firstName}>
          {({ input, meta }) => (
            <TextField
              {...input}
              autoFocus={true}
              label="First Name"
              errorMessage={renderErrorMessage(meta)}
              maxLength={255}
            />
          )}
        </Field>
        <Field name="lastName" validate={validations.lastName}>
          {({ input, meta }) => (
            <TextField
              {...input}
              label="Last Name"
              errorMessage={renderErrorMessage(meta)}
              maxLength={255}
            />
          )}
        </Field>
      </FormGroup>
      <FormGroup>
        <Field name="companyName" validate={validations.companyName}>
          {({ input, meta }) => (
            <TextField
              {...input}
              label="Legal Company Name"
              errorMessage={renderErrorMessage(meta)}
              maxLength={255}
            />
          )}
        </Field>
      </FormGroup>

      <FormGroup>
        <AutoCompleteField
          name="country"
          label="Country"
          placeholder="Select"
          options={countryOptions}
          validate={validations.country}
        />

        <StateAutocompleteField
          name="state"
          placeholder="Select"
          label="State/Province"
          country={values.country}
          validate={validations.state}
        />
      </FormGroup>

      <FormGroup>
        <Field name="email" validate={validations.email}>
          {({ input, meta }) => (
            <TextField
              {...input}
              label="Email"
              errorMessage={renderErrorMessage(meta)}
              maxLength={255}
            />
          )}
        </Field>
      </FormGroup>
      <FormGroup>
        <Field
          name="phoneNumber"
          validate={(value) =>
            validations.phone(`+${value.dialCode}${value.phone}`, value.iso2)
          }
        >
          {({ input, meta }) => (
            <PhoneField
              {...input}
              placeholder="Your phone number"
              label="Phone Number"
              separateDialCode={true}
              errorMessage={renderErrorMessage(meta)}
            />
          )}
        </Field>
      </FormGroup>

      {hasPasswordField && (
        <>
          <FormGroup>
            <Field
              name="password"
              maxLength={255}
              validate={(value) => {
                const requiredError = required('Please enter a password')(
                  value,
                );

                if (requiredError) {
                  return requiredError;
                }

                return validatePassword(value);
              }}
            >
              {({ input, meta }) => (
                <PasswordField
                  {...input}
                  label="Password"
                  placeholder="Enter 8 characters or more"
                  errorMessage={renderErrorMessage(meta)}
                />
              )}
            </Field>
          </FormGroup>

          <PasswordStrength value={values.password} />
        </>
      )}

      <FormGroup>
        <Field name="carsPerMonth" validate={validations.carsPerMonth}>
          {({ input, meta }) => (
            <RadioGroupField
              column={true}
              values={carsPerMonthOptions}
              name="carsPerMonth"
              checked={carsPerMonthOptions.findIndex(
                (x) => x.value === input.value,
              )}
              label="How many cars do you move per month?"
              errorMessage={renderErrorMessage(meta)}
              onClick={(value: any) => {
                input.onChange(value);
              }}
            />
          )}
        </Field>
      </FormGroup>
      <FormGroup>
        <Field name="companyType" validate={validations.companyType}>
          {({ input: { value, onChange }, meta }) => (
            <SelectField
              value={value && companyTypes.indexOf(value)}
              label="Company Type"
              height={56}
              placeholder="Select a company type..."
              errorMessage={
                meta.touched &&
                (!value || !companyTypes.includes(value)) &&
                'Please select a company type'
              }
              items={
                companyTypes.map((o, i) => ({
                  value: i,
                  text: o,
                })) as any
              }
              onChange={(v) => {
                onChange(companyTypes[Number(v)]);
              }}
            />
          )}
        </Field>

        <Field name="comment">
          {({ input: { value, onChange } }) => (
            <SelectField
              value={referralOptions.indexOf(value)}
              label={
                <OptionalTextFieldLabel>
                  How did you hear about us? <span>(optional)</span>
                </OptionalTextFieldLabel>
              }
              height={56}
              placeholder="Select a source..."
              items={
                referralOptions.map((o, i) => ({
                  value: i,
                  text: o,
                })) as any
              }
              onChange={(v) => {
                onChange(referralOptions[Number(v)]);
              }}
            />
          )}
        </Field>
      </FormGroup>

      <ConditionalField when="comment" is="Other">
        <FormGroup>
          <Field name="referralSource">
            {({ input, meta }) => (
              <TextField
                {...input}
                maxLength={255}
                errorMessage={renderErrorMessage(meta)}
                placeholder="Please, specify the source..."
              />
            )}
          </Field>
        </FormGroup>
      </ConditionalField>

      <FormGroup>
        <CaptchaWidget onTokenChange={onCaptchaTokenChange} />
      </FormGroup>

      <FormGroup>
        <Field name="subscriptions">
          {({ input }) => (
            <Checkbox
              {...input}
              checked={input.value}
              label="I agree to receive SMS and Email messages from Super Dispatch such as the latest offers and deals."
            />
          )}
        </Field>
      </FormGroup>
    </>
  );
}
