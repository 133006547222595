import * as React from 'react';
import Turnstile from 'react-turnstile';
import styled from 'styled-components';
import { captureError, logInfo, logWarning } from '../utils/datadog';
import { getSiteKey } from './captchaHelper';

const Wrapper = styled.div`
  width: 296px;
  height: 62px;
`;

interface CaptchaWidgetProps {
  onTokenChange: (token: string | null) => void;
}

export function CaptchaWidget({ onTokenChange }: CaptchaWidgetProps) {
  return (
    <Wrapper>
      <Turnstile
        theme="light"
        language="en"
        retryInterval={3_000}
        sitekey={
          import.meta.env.VITE_TARGET === 'production'
            ? '0x4AAAAAAAD-dheBcA6EjcK3'
            : getSiteKey()
        }
        onVerify={(token) => {
          logInfo('Captcha Verification passed');
          onTokenChange(token);
        }}
        onError={(error, turnstile) => {
          captureError('Failed to load Turnstile', 'CaptchaWidget#onError', {
            error,
            isExpired: turnstile?.isExpired(),
            response: turnstile?.getResponse(),
          });
        }}
        onExpire={() => {
          logWarning('Captcha Verification expired');
          onTokenChange(null);
        }}
      />
    </Wrapper>
  );
}
