import { useEffect, useState } from 'react';

export function useInitialState<T>(value: T) {
  const [state, setState] = useState<T>();

  useEffect(() => {
    setState((prev) => {
      if (!prev) {
        return value;
      }
      return prev;
    });
  }, [value]);

  return state;
}
