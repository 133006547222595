import { Button as SDKitButton } from '@sdkit/button';
import { ChangeEvent, useEffect, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import {
  APIResponse,
  isAPIErrorResponse,
  verifyCode,
  verifyEmail,
} from '../../data/CarrierAPI';
import ArrowForwardIcon from '../../shared/assets/arrow-forward.svg?react';
import OpenInNewIcon from '../../shared/assets/open-in-new.svg?react';
import { trackEvent } from '../../shared/helpers/AnalyticsHelpers';
import { Button } from '../../shared/ui/Button';
import { TextField } from '../../shared/ui/TextField';

const EmailVerifyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const StyledArrowForwardIcon = styled(ArrowForwardIcon)`
  margin-left: 10px;
`;
const EmailVerifyTitle = styled.div`
  font-size: 16px;
  line-height: 28px;
`;
const EmailVerifyActions = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
`;
const StyledOpenEmailButton = styled(Button)`
  & span {
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 10px;
    & svg {
      margin-top: -2px;
    }
    & svg:not(:hover) {
      color: #8f949e;
    }
  }
  &:hover {
    & span svg {
      color: inherit;
    }
  }
`;
const EmailVerifyBackButton = styled(Button)`
  &&,
  &&:hover {
    background: white;
    color: #0070f5;
  }
`;
const StyledHelperText = styled.span`
  font-size: 16px;
  margin: 5px 0;
  line-height: 20px;
  color: #5b6371;
  word-break: break-word;
  white-space: break-spaces;
`;
const StyledVerificationCodeField = styled(TextField)`
  letter-spacing: 4px;
`;
const Link = styled.a`
  color: #0063db;
  cursor: pointer;
  text-decoration: none;
`;

let RESEND_INTERVAL_SECONDS = 10;

function ResendCodeButton({ email }: { email: string }) {
  const [canResend, setCanResend] = useState(true);
  const [countdown, setCountdown] = useState(RESEND_INTERVAL_SECONDS);

  useEffect(() => {
    if (!canResend) {
      const timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev === 1) {
            clearInterval(timer);
            setCanResend(true);
            return RESEND_INTERVAL_SECONDS;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [canResend]);

  const sendVerificationCodeMutation = useMutation({
    mutationFn: verifyEmail,
    onMutate: () => {
      setCanResend(false);
    },
    onSuccess: (response: APIResponse) => {
      if (isAPIErrorResponse(response)) {
        if (response.error.type === 'TooManyRequests') {
          RESEND_INTERVAL_SECONDS = 30 * 60;
        }
        toast.error(response.error.user_message || 'Something went wrong');
      } else {
        setCanResend(false);
        setTimeout(() => setCanResend(true), RESEND_INTERVAL_SECONDS * 1000);
        setCountdown(RESEND_INTERVAL_SECONDS);
        RESEND_INTERVAL_SECONDS = 10;
      }
    },
    onError: (error: Error) => {
      toast.error(error.message);
    },
  });

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(
      remainingSeconds,
    ).padStart(2, '0')}`;
  };

  return canResend ? (
    <Link
      onClick={() => {
        sendVerificationCodeMutation.mutate(email);
        setCanResend(false);
      }}
    >
      Resend code
    </Link>
  ) : (
    <span>Resend in {formatTime(countdown)}</span>
  );
}

interface VerifyEmailProps {
  onBack: () => void;
  sniperLink: string | undefined;
}
export function VerifyEmail({ onBack, sniperLink }: VerifyEmailProps) {
  const { values } = useFormState();
  const { submit } = useForm();
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>();

  const verifyCodeMutation = useMutation({
    mutationFn: () => verifyCode(values.email, verificationCode),
    onSuccess: (response: APIResponse) => {
      if (isAPIErrorResponse(response)) {
        toast.error(
          response.error.user_message ||
            response.error.type ||
            'Something went wrong',
        );

        if ((response.error.context as Record<string, string>)?.code) {
          setErrorMessage(
            (response.error.context as Record<string, string>)?.code,
          );
        }
      } else {
        submit();
      }
    },
    onError: (error: Error) => {
      toast.error(error.message);
    },
  });

  useEffect(() => {
    document.title = 'Carrier Sign Up | Confirm Email';
    trackEvent('Opened Confirm Email');

    return () => {
      document.title = 'Carrier Sign Up | Create Account';
    };
  }, []);

  return (
    <EmailVerifyWrapper>
      <EmailVerifyTitle>
        We&apos;ve sent you a temporary 6-digit code at <b>{values.email}</b>.
        <br />
        Copy and paste it below.
      </EmailVerifyTitle>
      <div>
        <FieldWrapper>
          <StyledVerificationCodeField
            label="6-digit Code"
            maxLength={6}
            placeholder="XXXXXX"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const value = e.target.value;
              if (/^\d*$/.test(value)) {
                setVerificationCode(value);
                setErrorMessage(undefined);
              }
            }}
            value={verificationCode}
            errorMessage={errorMessage}
          />

          <StyledHelperText>
            If you didn&apos;t receive it check spam folder or{' '}
            <ResendCodeButton email={values.email} />.
          </StyledHelperText>
        </FieldWrapper>
      </div>

      {sniperLink && (
        <StyledOpenEmailButton
          onClick={() => {
            window.open(sniperLink, '_blank');
          }}
          type="button"
          color={SDKitButton.SECONDARY}
        >
          Open My Email <OpenInNewIcon />
        </StyledOpenEmailButton>
      )}

      <EmailVerifyActions>
        <Button
          size="large"
          disabled={verificationCode?.length !== 6}
          onClick={(e) => {
            e.preventDefault();
            verifyCodeMutation.mutate();
          }}
          isLoading={verifyCodeMutation.isLoading}
        >
          Continue <StyledArrowForwardIcon />
        </Button>
        <EmailVerifyBackButton onClick={onBack} type="button">
          Go Back
        </EmailVerifyBackButton>
      </EmailVerifyActions>
    </EmailVerifyWrapper>
  );
}
